import React, { FC }  from 'react';
import './wasteETable.scss';

import triangle from '../../assets/icons/triangle-bottom.svg';

import { formatDate } from '../../services/services';

interface Props{
  data: any[];
  setData: any;
  columns: {
    label: string;
    sort?: boolean;
    property?: string;
    image?: any;
    onClick?: any;
    type?: string; // text, image, date
  }[]
}


export const COL_TYPES = {
  date: 'date',
  text: 'text',
  image: 'image',
}

const WasteETable: FC<Props> = ({data, setData, columns}) =>{

  // console.log(data);

  function sort(fieldName: string, type?: string){

    let sorted;
    if(type === COL_TYPES.date) sorted = [...data].sort((i1: any, i2: any) => i1[fieldName] - i2[fieldName])
    else sorted =  [...data].sort((i1: any, i2: any) => i1[fieldName].localeCompare(i2[fieldName]))
    setData(sorted);
  }

  return (
    <table className="wasteETable">
      <thead>
        <tr>
          {columns.map((item, key) => (
            <th key={key}>
              <div className="wasteETable__table-head">
                <p>{item.label}</p> 
                {item.sort && <img src={triangle} onClick={()=>sort(item.property || "", item.type)} alt="sort"/>}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((dataEl, key) => (
          <tr key={key}>
            {columns.map((col, key2) => {
                const onClick = ()=> col.onClick && col.onClick(dataEl);
                if(col.type === COL_TYPES.image || col.image) return (
                  <td key={key2}>
                    <div className="wasteETable__table-image" onClick={onClick}><img src={col.image} alt={col.label}/></div>
                  </td>
                )
                
                if(col.property){
                  let text = dataEl[col.property];     
                  if(col.type === COL_TYPES.date) text = formatDate(text);      
                  return (
                    <td key={key2} onClick={onClick}><p>{text}</p></td>
                  ) 
                }

                return <td key={key2} onClick={onClick}>No INFO</td>
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default WasteETable;