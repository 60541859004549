import uniqid from 'uniqid';

export function getFileName(originalName: string){
  const ext = originalName.split('.').pop();
  return uniqid('img-', '.') + ext;
}

export function formatDate(date: Date){
  const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Spetiembre', 'Octubre', 'Noviembre', 'Diciembre']
  return `${date.getDate()} de ${months[date.getMonth()]} de ${date.getFullYear()}`
}

export function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

export function validatePassword(pass: string) {
  return pass.length > 7;
}

export function validateCel(cel: string) {
  const reg = /^[+ 0-9]{10}$/;;
  return reg.test(cel);
}