import React, {useState, useEffect} from 'react';
import {Grid, InputBase, MenuItem, Select} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory } from "react-router";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  useAuth,
  useFirestore,
} from 'reactfire';
import * as firebase from 'firebase';

// scss
import './register.scss';

// assets
// import logo from '../../assets/wastelogo.svg';
import google from '../../../assets/google.svg';
import { validateEmail, validateCel, validatePassword } from '../../../services/services';

const cities=[{
  label:'Bogotá',
  value:'Bogota',
},{
  label:'Villavicencio',
  value:'Villavicencio',
},{
  label:'Medellín',
  value:'Medellin',
},{
  label:'Cali',
  value:'Cali',
},{
  label:'Barranquilla',
  value:'Barranquilla',
},{
  label:'Cartagena',
  value:'Cartagena',
},{
  label:'Soledad',
  value:'Soledad',
},{
  label:'Cúcuta',
  value:'Cucuta',
},{
  label:'Ibagué',
  value:'Ibague',
},{
  label:'Bucaramanga',
  value:'Bucaramanga',
},{
  label:'Santa Marta',
  value:'Santa Marta',
},{
  label:'Valledupar',
  value:'Valledupar',
},{
  label:'Pereira',
  value:'Pereira',
},{
  label:'Montería',
  value:'Monteria',
},{
  label:'Pasto',
  value:'Pasto',
},{
  label:'Buenaventura',
  value:'Buenaventura',
},{
  label:'Manizales',
  value:'Manizales',
},{
  label:'Neiva',
  value:'Neiva',
},
]

function FirstStep({form, isCompany, setStep, loginGoogle, saveField, validation, setValidation}: any) {
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      style={{marginTop: '-50px'}} //revisar margen cambiar!
    >
      <Grid
        container
        className="register__container"
        direction="column"
        justify="center"
        alignItems="center"
      >
        {isCompany ? (
          <p className="register__title">
            Solo necesitamos unos datos de tu empresa
          </p>
        ) : (
          <p className="register__title">Solo necesitamos unos datos tuyos</p>
        )}
        <InputBase
          placeholder="Nombre"
          className="register__input"
          onChange={(e: React.ChangeEvent<{value: unknown}>) =>{
            saveField('name', (e.target as HTMLSelectElement).value);
            if(form.name !== "" ) setValidation({...validation, name: {...validation.name, state:true}});
            else setValidation({...validation, name: {...validation.name, state:false}});
          }
          }
        />
        {isCompany ? (
          <InputBase
            placeholder="Nit"
            className="register__input"
            onChange={(e: React.ChangeEvent<{value: unknown}>) =>{
              saveField('nit', (e.target as HTMLSelectElement).value);
              if(form.nit !== "" ) setValidation({...validation, nit: {...validation.nit, state:true}});
              else setValidation({...validation, nit: {...validation.nit, state:false}});
            }
            }
          />
        ) : (
          <InputBase
            placeholder="Apellido"
            className="register__input"
            onChange={(e: React.ChangeEvent<{value: unknown}>) =>{
              saveField('lastname', (e.target as HTMLSelectElement).value);
              if(form.lastname !== "" ) setValidation({...validation, lastname: {...validation.lastname, state:true}});
              else setValidation({...validation, lastname: {...validation.lastname, state:false}});
              }
            }
          />
        )}
        <InputBase
          placeholder="Correo"
          className="register__input"
          onChange={(e: React.ChangeEvent<{value: unknown}>) =>
            saveField('email', (e.target as HTMLSelectElement).value)
          }
        />
        <InputBase
          placeholder="Contraseña"
          className="register__input"
          type="password"
          onChange={(e: React.ChangeEvent<{value: unknown}>) =>
            saveField('password', (e.target as HTMLSelectElement).value)
          }
        />
        <InputBase
          placeholder="Confirma tu contraseña"
          className="register__input"
          type="password"
          onChange={(e: React.ChangeEvent<{value: unknown}>) =>
          saveField('password2', (e.target as HTMLSelectElement).value)
        }
        />
        <Grid>
          {validation.name.state ? <></>: <p className="register__error">{validation.name.message}</p> }
          {validation.lastname.state ? <></>: <p className="register__error">{validation.lastname.message}</p>}
          {validation.nit.state ? <></>: <p className="register__error">{validation.nit.message}</p>}
          {validation.email.state ? <></>: <p className="register__error">{validation.email.message}</p> }
          {validation.password.state ? <></>: <p className="register__error">{validation.password.message}</p>}
          {validation.password2.state ? <></>: <p className="register__error">{validation.password2.message}</p>}
        </Grid>
        
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{maxWidth: '70%', margin: '32px 0'}}
        >
          <Grid item container alignItems="center" justify="space-between">
            <Grid>
              <img
                onClick={loginGoogle}
                src={google}
                style={{cursor: 'pointer'}}
                alt="google"
              ></img>
            </Grid>
            <Grid>
              <div
                onClick={() => {
                  if(form.name === "" ) {
                    setValidation({...validation, name: {...validation.name, state:false}});
                    return ;
                  }
                  if(form.lastname === "" && !isCompany) {
                    setValidation({...validation, lastname: {...validation.lastname, state:false}});
                    return ;
                  }
                  if(form.nit === "" && isCompany) {
                    setValidation({...validation, nit: {...validation.nit, state:false}});
                    return ;
                  }
                  if(form.email === "" ) {
                    setValidation({...validation, email: {...validation.email, state:false}});
                    return ;
                  }
                  if(form.password === "" ) {
                    setValidation({...validation, password: {...validation.password, state:false}});
                    return ;
                  }
                  if(form.password2 === "" ) {
                    setValidation({...validation, password2: {...validation.password2, state:false}});
                    return ;
                  }
                  if(!(validation.email.state && validation.password.state && validation.password2.state)) return;
                  
                  setStep(1);
                }}
                className="green-button-register"
              >
                Únete
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function SecondStep({
  isCompany,
  saveField,
  save,
  form,
  validation,
  setValidation
}: any) {
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      style={{marginTop: '-50px'}}
    >
      <Grid
        container
        className="register__container"
        direction="column"
        justify="center"
        alignItems="center"
      >
        <p className="register__title">Solo faltan unos más...</p>
        <InputBase
          placeholder="Direccion favorita para que recojamos"
          className="register__input"
          onChange={(e: React.ChangeEvent<{value: unknown}>) =>{
            saveField('address', (e.target as HTMLSelectElement).value);
            if(form.address !== "" ) setValidation({...validation, address: {...validation.address, state:true}});
            else setValidation({...validation, address: {...validation.address, state:false}});

          }
          }
        />
        {isCompany ? (
          <InputBase
            placeholder="Número de contacto"
            className="register__input"
            type="number"
            onChange={(e: React.ChangeEvent<{value: unknown}>) =>
              saveField('cellphone', (e.target as HTMLSelectElement).value)
            }
          />
        ) : (
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            style={{width: '70%'}}
          >
            <p className="register__subtitle">Fecha de Nacimiento</p>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className="register__datepicker"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                value={form.birth}
                onChange={(date: Date | null) =>
                  saveField('birth', date)
                }
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          style={{width: '70%'}}
        >
          <p className="register__subtitle">Ciudad de recogida</p>
          <Select
            className="register__select"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={form.city}
            onChange={(e: React.ChangeEvent<{value: unknown}>) =>
              saveField('city', (e.target as HTMLSelectElement).value)
            }
          >
            {cities.map((item,key)=>(
              <MenuItem key={key} value={item.value}>{item.label}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid>
          {validation.address.state ? <></>: <p className="register__error">{validation.address.message}</p>}
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-end"
          style={{width: '70%', marginTop: '20px', marginBottom: '50px'}}
        >
          <div onClick={save} className="green-button-register">
            Comencemos
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}


function Register() {
  const history = useHistory<any>();
  let state:any = history.location.state || {isCompany: false};
  let _useAuth = useAuth();
  let _useFirestore = useFirestore();
  const [step, setStep] = useState(0);

  const [form, setForm] = useState<any>({
    uid: '',
    name: '',
    lastname: '',
    nit: '',
    email: '',
    password: '',
    password2: '',
    address: '',
    cellphone: '',
    city: 'Bogota',
    role: 'USER',
    birth: new Date(),
  });

  const [validation, setValidation] = useState<any>({
    name: {
      state: true,
      message: "El nombre no puede ser vacio",
    },
    lastname: {
      state: true,
      message: "El apellido no puede ser vacio",
    },
    nit: {
      state: true,
      message: "El nit no puede ser vacio",
    },
    email: {
      state: true,
      message: "Correo Electronico invalido",
    },
    password: {
      state: true,
      message: "La contraseña debe tener minimo 8 caracteres",
    },
    password2: {
      state: true,
      message: "Las contraseñas no coinciden",
    },
    cellphone: {
      state: true,
      message: "Numero de celular invalido",
    },
    address: {
      state: true,
      message: "La dirección no puede ser vacia",
    },
  });

  useEffect(() => {
    if(validateEmail(form.email) || form.email==="") setValidation({...validation, email: {...validation.email, state:true}});
    else setValidation({...validation, email: {...validation.email, state:false}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.email]);

  useEffect(() => {
    if(validateCel(form.cellphone) || form.cellphone==="") setValidation({...validation, cellphone: {...validation.cellphone, state:true}});
    else setValidation({...validation, cellphone: {...validation.cellphone, state:false}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.cellphone]);

  useEffect(() => {
    if(!validatePassword(form.password)) setValidation({...validation, password: {...validation.password, state:false}});
    else if(form.password !== form.password2 && form.password2 !== "") setValidation({...validation, password2: {...validation.password2, state:false}});
    else setValidation({...validation, password: {...validation.password, state:true}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.password]);

  useEffect(() => {
    if(form.password === form.password2 ) setValidation({...validation, password2: {...validation.password2, state:true}});
    else setValidation({...validation, password2: {...validation.password2, state:false}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.password2]);


  // console.log("validacion");
  // console.log(validation);
  const loginGoogle = () => {
    _useAuth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((result) => {
        let {user} = result;
        setForm({
          ...form,
          uid: user?.uid.toString(),
          name: user?.displayName,
          email: user?.email,
          cellphone: user?.phoneNumber,
          city: 'Bogota',
        });
        setStep(1);
      });
  };

  const saveField = (field: any, value: any) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const save = async () => {

    if(form.city !=="Bogota" && form.city !== "Villavicencio" ){
      alert("Pronto operaremos en esas ciudades");
      history.push("/")
      return;
    }
    if(form.address === ""){
      setValidation({...validation, address: {...validation.address, state:false}});
      return ;
    }

    let addInfo;
    if (!form.uid) {
      const result = await _useAuth.createUserWithEmailAndPassword(form.email, form.password)
      let {user}: any = result;
      addInfo = {...form, uid: user?.uid, password: "", password2: "", };
    } else {
      addInfo = form;
    }
    const resultCollection = await (await _useFirestore.collection('user').add(addInfo)).get();
    
    // alert('Registro realizado');
    localStorage.userid = resultCollection.id;
    history.push("/form");
  };

  switch (step) {
    case 0:
      return (
        <FirstStep
          form={form}
          saveField={saveField}
          loginGoogle={loginGoogle}
          isCompany={state.isCompany}
          setStep={setStep}
          validation={validation}
          setValidation={setValidation}
        ></FirstStep>
      );
    case 1:
      return (
        <SecondStep
          form={form}
          saveField={saveField}
          isCompany={state.isCompany}
          save={save}
          validation={validation}
          setValidation={setValidation}
        ></SecondStep>
      );
    default:
      return <p>No Info</p>;
  }
}

export default Register;
