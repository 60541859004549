import React, {Suspense, useContext} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch, HashRouter} from 'react-router-dom';
import {FirebaseAppProvider} from 'reactfire';
import {MainContext} from './context/MainContext'


import {firebaseConfig} from './config';

import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';

//scss
import './index.css';
import './globals.scss';

//views&components
import Landing from './views/MainPage/landing/landing';
import Login from './views/MainPage/login/login';
import AdminHome from './views/Admin/adminHome/adminHome';
import AdminRecicladores from './views/Admin/adminRecicladores/adminRecicladores';
import Contactenos from './views/MainPage/contactenos/contactenos';
import Nosotros from './views/MainPage/nosotros/nosotros';

import Register from './views/User/register/register';
import Profile from './views/User/profile/profile';
import Form from './views/User/form/form';
import List from './views/User/list/list';

import AdminUsuarios from './views/Admin/adminUsuarios/adminUsarios';
import AdminEntregas from './views/Admin/adminEntregas/adminEntregas';
import AdminCreation from './views/Admin/adminCreation/adminCreation';
import AdminActualizar from './views/Admin/adminActualizar/adminActualizar';
import AdminEntregaCoordinar from './views/Admin/adminEntregaCoordinar/adminEntregaCoordinar';

import MainProvider from './context/MainContext';


const routes = [{
  path: '/',
  render: (props)=> <Landing {...props}/>,
  admin: false,
}, {
  path: '/login',
  render: (props)=> <Login {...props}/>,
  admin: false,
}, {
  path: '/contactenos',
  render: (props)=> <Contactenos {...props}/>,
  admin: false,
}, {
  path: '/nosotros',
  render: (props)=> <Nosotros {...props}/>,
  admin: false,
}, {
  path: '/register',
  render: (props) => <Register {...props} isCompany />,
  admin: false,
}, {
  path: '/profile',
  render: (props)=> <Profile {...props}/>,
  admin: false,
}, {
  path: '/profile/enCurso',
  render: (props) => <Profile {...props} defStep={1} />,
  admin: false,
}, {
  path: '/profile/finalizadas',
  render: (props) => <Profile {...props} defStep={2} />,
  admin: false,
}, {
  path: '/admin/home',
  render: (props)=> <AdminHome {...props}/>,
  admin: true,
}, {
  path: '/admin/usuarios',
  render: (props)=> <AdminUsuarios {...props}/>,
  admin: true,
}, {
  path: '/admin/entregas/curse',
  render: (props) => <AdminEntregas {...props} onlyView curse />,
  admin: true,
}, {
  path: '/admin/entregas/end',
  render: (props) => <AdminEntregas {...props} onlyView end />,
  admin: true,
}, {
  path: '/admin/entregas/gestion',
  render: (props)=> <AdminEntregas {...props}/>,
  admin: true,
}, {
  path: '/admin/recicladores',
  render: (props)=> <AdminRecicladores {...props}/>,
  admin: true,
}, {
  path: '/admin/creationCliente',
  render: (props) => <AdminCreation {...props} isCliente={true} />,
  admin: true,
}, {
  path: '/admin/creationReciclador',
  render: (props) => <AdminCreation {...props} isCliente={false} />,
  admin: true,
}, {
  path: '/admin/actualizarUsuario',
  render: (props) => <AdminActualizar {...props} isReciclador={false} />,
  admin: true,
}, {
  path: '/admin/actualizarReciclador',
  render: (props) => <AdminActualizar {...props} isReciclador={true} />,
  admin: true,
}, {
  path: '/admin/entregaCoordinar/:entregaid',
  render: (props)=> <AdminEntregaCoordinar {...props}/>,
  admin: true,
}, {
  path: '/form',
  render: (props)=> <Form {...props}/>,
  admin: false,
}, {
  path: '/list',
  render: (props)=> <List {...props}/>,
  admin: false,
}]



const Routes = ()=>{

  const {user} = useContext(MainContext);  

  function getRender(el){
    if(el.admin && user?.role !== 'ADMIN') return ()=><p>No tiene permiso para ver esto</p>
    return el.render;
  }

  return routes.map((el, key) => <Route exact path={el.path} key={key} render={getRender(el)}/>)
}



ReactDOM.render(
  <div className="main-container">
    <React.StrictMode>
      <Suspense fallback={<p>Cargando...</p>}>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
          <MainProvider>
            <BrowserRouter>
              <Switch>
                <HashRouter>
                  <Navbar />
                  <Routes />
                  <Footer />
                </HashRouter>
              </Switch>
            </BrowserRouter>
          </MainProvider>
        </FirebaseAppProvider>
      </Suspense>
    </React.StrictMode>
  </div>,
  document.getElementById('root')
);
