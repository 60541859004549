import React, {useState, useContext} from 'react';
import {Grid, InputBase, MenuItem, Select} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { MainContext } from '../../../context/MainContext'

import moment from 'moment';

import {
  useFirestore,
} from 'reactfire';

import * as firebase from 'firebase';
import { getFileName } from '../../../services/services'


// scss
import './list.scss';

// assets
import entrega from '../../../assets/entrega.svg';
import BackButton from '../../../components/backButton/backButton';
import cross from '../../../assets/icons/cross red.svg';
import { useHistory } from 'react-router-dom';

function Table({listItems,delFun,setStep}:any) {
  const history = useHistory();
  return(
    <Grid container direction="column" alignItems="flex-start" justify="center" className="list">
      <p className="list__title">Estado de tu entrega</p>
      <Grid container justify="flex-start" alignItems="center" className="list__subtitle-container">
        <img src={entrega} alt="entrega icono" style={{width:"80px"}} />
        <p className="list__subtitle">Dispositivos que entregarás</p>
      </Grid>

      <table className="list__table">
        <thead>
          <tr>
            <th>Dispositivo </th>
            <th>Marca </th>
            <th>Estado </th>
            <th>¿Como podemos llevarlo? </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {listItems.map((item:any,key:any)=>(
            <tr key={key} >
              <td>
                {item.type}
              </td>
              <td>
                {item.marca}
              </td>
              <td>
                {item.size}
                {item.estado}
              </td>
              <td>
                {item.weight}
                {item.transporte}
              </td>
              <td>
                <div className="list__table--cross" onClick={()=>{
                  delFun(item.id, key)}}
                  >
                  <img src={cross} alt="eliminar_icon"/>
                </div>
              </td>
            </tr>
          ))}
        </tbody>

      </table>

      <Grid container direction="column" justify="center" alignItems="flex-end" style={{marginTop:"72px"}}>
        <div className="green-button" style={{padding:"14px 16px", margin:"12px 0"}} onClick={()=>history.push("/form")}>
          <strong>Agregar otro dispositivo</strong>
        </div>
        <div className="green-button" style={{padding:"14px 16px", margin:"12px 0"}} onClick={()=>setStep(1)}>
          <strong>Termina de programar tu entrega</strong>
        </div>
      </Grid>
    </Grid>
  )
}

function Finish({setStep, saveEntrega}: any) {

  const {user} = useContext(MainContext);
  const userInfo = user;
  const [entrega, setEntrega] = React.useState({
    city:userInfo?.city,
    address:userInfo?.address,
    date: moment().add(3, "d").toDate(),
    franja:'', 
    state: 'NEW'
  });

  const [message, setMessage] = React.useState("");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEntrega({...entrega,city:event.target.value as string});
  };
  const handleFranja = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEntrega({...entrega,franja:event.target.value as string});
  };

  const handleDateChange = (date: Date | null) => {
    if(date == null) return
    const dateMom = moment(date);
    const diff = dateMom.diff(moment(), 'days');
    if(diff < 2) {
      setMessage("La fecha mínima de recolección debe ser en almenos 2 dias");
      return;
    }

    if(dateMom.day() === 0){
      setMessage("La fecha de recolección no puede ser un Domingo");
      return;
    }

    setMessage("");
    setEntrega({...entrega, date});
  };

  const {productList} = useContext(MainContext);

  return(
    <Grid container direction="column" alignItems="flex-start" justify="center" style={{padding:"0 68px"}}>
      <Grid container justify="flex-start" alignItems="flex-start" direction="column">
        <p className="list__title">Termina de programar tu entrega</p>
        <Grid container alignItems="flex-start" justify="space-between" direction="row">
          <Grid container alignItems="center" justify="space-between" direction="column" style={{width:"30%"}}>
            <p className="list__what">¿Qué vamos a recoger?</p>
            { (productList.length === 0) ? <p>No hay productos</p> : (
              <div className="list__endItemsTable">
                {productList.map((item, key)=>(
                  <div key={key} className={"list__endItemTable" + ((key === 0) ? " list__endItemTable--first": "")}>
                    {item.type}
                  </div>
                ))}
              </div>
            )}
            

          </Grid>
          <Grid container alignItems="flex-start" justify="space-between" direction="column" style={{width:"60%"}}>
            <Grid >
              <p className="profile__subtitle" style={{marginBottom:"16px"}}>Ciudad</p>
              <Grid container direction="row">
                <Select
                  className="profile__select"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={entrega.city}
                  onChange={handleChange}
                >
                  <MenuItem value={"Bogota"}>Bogotá</MenuItem>
                  <MenuItem value={"Villavicencio"}>Villavicencio</MenuItem>
                </Select>
              </Grid>
              <p className="profile__subtitle" style={{marginBottom:"8px"}}>Dirección</p>
              <Grid container direction="row">
                <InputBase
                  placeholder="Dirección favorita para que recojamos"
                  className="profile__input"
                  value={entrega.address}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => setEntrega({...entrega,address: e.target.value as string})}
                />
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <p className="profile__subtitle" style={{marginTop:"8px"}}>¿Qué dia quieres que recojamos?</p>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className="register__datepicker"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={entrega.date}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <p>{message}</p>

              <p className="profile__subtitle" style={{marginBottom:"16px"}}>Franja horaria para recoger</p>
              <Grid container direction="row">
                <Select
                  className="profile__select"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={entrega.franja}
                  onChange={handleFranja}
                >
                  <MenuItem value={"6:00am - 8:00am"}>6:00am - 8:00am</MenuItem>
                  <MenuItem value={"8:00am - 12:00pm"}>8:00am - 12:00pm</MenuItem>
                  <MenuItem value={"2:00pm – 5:00pm"}>1:00pm - 6:00pm</MenuItem>
                </Select>
              </Grid>
              <Grid container direction="row" justify="space-between" style={{margin:"100px 0"}}>
                <BackButton onClick={()=>setStep(0)}></BackButton>
                <div className="form__add" onClick={()=>saveEntrega(entrega)}>
                  Agendar
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

function List() {

  const [step, setStep] = useState(0);
  let _useFirestore = useFirestore();
  const history = useHistory();

  const {productList, setProductList} = useContext(MainContext);

  const deleteCurrent = (id:any, index: number) => {
    productList.splice(index, 1);
    setProductList([...productList])
  };

  if(!localStorage.userid){
    history.push("/");
  }

  const saveEntrega = async (data:any) => {


    if(data.date == null) return
    const dateMom = moment(data.date);
    const diff = dateMom.diff(moment(), 'days');
    // Entrega en menos de 2 dias o un domingo
    if(diff < 2 || dateMom.day() === 0) return;
    

    try{
      const userInfo = localStorage.userid;
      if(!userInfo) return;

      const storage = firebase.storage();
      const storageRef = storage.ref();

      for(const product of productList){
        
        const fileName = getFileName(product.photo.name);

        await storageRef.child('orders/' + fileName).put(product.photo);
        product.photo = fileName;

        // const imgRef = storage.ref('orders/' + fileName);
        // const downloadUrl = await imgRef.getDownloadURL();
      }
      await _useFirestore.collection('entrega').add({...data, productList, userid: localStorage.userid, creation: new Date()});
      setProductList([]);
      history.push("/form")

    }catch(error){
      console.log("Hubo un error entrega");
      console.log(error);
    }
  };

  switch (step) {
    case 0:
      return (
        <Table listItems={productList} delFun={deleteCurrent} setStep={setStep} />
      );
    case 1:
      return (
        <Finish setStep={setStep} saveEntrega={saveEntrega}/>
      );
    default:
      return <p>No Info</p>;
  }


}

export default List;
