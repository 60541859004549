import React, { useState, useEffect, useContext } from 'react';
import {Grid, InputBase, MenuItem, Select} from '@material-ui/core';
import {
  useFirestore,
} from 'reactfire';
import * as firebase from 'firebase';
import { useHistory } from "react-router-dom";
import { getFileName, formatDate } from '../../../services/services'
import WasteETable from '../../../components/wasteETable/wasteETable';
import { MainContext } from '../../../context/MainContext'

// scss
import './profile.scss';
import lupa from '../../../assets/icons/buscar 1.svg';

// assets
import change from '../../../assets/icons/intercambio 1.svg';
import entregapersona from '../../../assets/entregapersona.svg';
import boxtime from '../../../assets/boxtime.svg';

import ProfileBase from '../../../components/profileBase/profileBase';
import ShowImgs from '../../../components/showImgs/ShowImgs';


import defaultProfImg from '../../../assets/icons/defaultUser.png';


function Current({step, setStep, user, setUser, updateUser}:any){
  const [entregaCurso, setEntregaCurso] = useState<any[]>([]);
  const [entregaFinal, setEntregaFinal] = useState<any[]>([]);
  const [entrega, setEntrega] = useState<any>({
    ciudad: '',
    fecha: '',
    direccion: '',
    dispositivo: '',
    estado: '',
    dimensiones: '',
    horario: '',
    operador: {},
    images: [],
  });
 
  const [operadorImg, setOperadorImg] = useState(defaultProfImg);

  // console.log("CLG DE USER");
  // console.log(user);

  const _useFirestore = useFirestore();

  async function getEntregasCurso(){
    console.log(localStorage.userid);
    const result = await _useFirestore.collection('entrega').where('state', "==", "EN CURSO").where('userid', "==", localStorage.userid).get();
    console.log(result);
    const arr:any[] = [];
    for(const res of result.docs) arr.push({...res.data(), id: res.id});
    const tableInfo = [];
    // console.log("format de entregas");
    for(const entrega of arr){
      // console.log(entrega);
      tableInfo.push({
        fechaRecoger: new Date(entrega.date.seconds * 1000),
        dispositivo: entrega.productList.map((el:any)=>el.type).join(', '),  
        productList: entrega.productList,
        estado: entrega.productList.map((el:any)=>el.estado).join(', '), 
        ciudad: entrega.city,
        direccion: entrega.address,
        id: entrega.id,
        horario: entrega.franja,
        dimensiones: entrega.productList.map((el:any)=>el.transporte).join(', '),
        operador: entrega.reciclador,
      })
    }


    setEntregaCurso(tableInfo);
  }
  
  async function entregaCursoClick(data: any){
    // console.log("este es el clg");
    // console.log(data);
    const operador = await (await _useFirestore.collection('reciclador').doc(data.operador).get()).data();
    if(!operador?.photo) setOperadorImg(defaultProfImg)
    else{
      const storage = firebase.storage();
      const storageRef = storage.ref('profile/' + operador.photo);
      storageRef.getDownloadURL().then((info)=>{
        setOperadorImg(info);
      }).catch((err)=>{
        console.log(err);
      })
    }

    const storage = firebase.storage();
    
    const newImgs = [];

    for(const prod of data.productList){
      const storageRef = storage.ref('orders/' + prod.photo);
      const info = await storageRef.getDownloadURL();
      newImgs.push(info);
    }

    setEntrega({
      ciudad: data.ciudad,
      fecha: data.fechaRecoger,
      direccion: data.direccion,
      dispositivo: data.dispositivo,
      estado: data.estado,
      dimensiones: data.dimensiones,
      horario: data.horario,
      operador: operador,
      images: newImgs,
    })

    setStep(3);
  }

  async function getEntregasFinal(){
    const result = await _useFirestore.collection('entrega').where('state', "==", "END").where('userid', "==", localStorage.userid).get();
    const arr:any[] = [];
    for(const res of result.docs) arr.push({...res.data(), id: res.id});
    const tableInfo = [];
    // console.log("format de entregas");
    for(const entrega of arr){
      // console.log(entrega);
      tableInfo.push({
        fechaRecoger: new Date(entrega.date.seconds * 1000),
        dispositivo: entrega.productList.map((el:any)=>el.type).join(', '),  
        estado: entrega.productList.map((el:any)=>el.estado).join(', '),  
        ciudad: entrega.city,
        direccion: entrega.address,
        id: entrega.id,
      })
    }

    setEntregaFinal(tableInfo);
  }
  

  useEffect(()=>{
    if(step === 1) getEntregasCurso();
    if(step === 2) getEntregasFinal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])


  

  switch(step){
    case 0:
      return (
      <>
        <Grid container direction="row" justify="space-between" >
          <div className="green-button" onClick={()=>setStep(1)} style={{paddingTop:"10px", paddingBottom:"10px"}}>Entregas en curso</div>
          <div className="green-button" onClick={()=>setStep(2)} style={{paddingTop:"10px", paddingBottom:"10px"}}>Entregas finalizadas</div>
        </Grid>
        <p className="profile__title">Tu información:</p>
        <p className="profile__subtitle">Nombre</p>
        <Grid container direction="row">
          <InputBase
            placeholder="Nombre"
            className="profile__input"
            value={user?.name}
            onChange={(name) =>
              setUser({...user, name: name.target.value})
            }
          />
          <img src={change} alt="cambiar" onClick={()=>updateUser(user)}className="profile__actualiza" />
        </Grid>
        <p className="profile__subtitle">Correo</p>
        <Grid container direction="row">
          <InputBase
            placeholder="Correo"
            className="profile__input"
            value={user?.email}
            onChange={(email) =>
              setUser({...user, email: email.target.value})
            }
          />
          <img src={change} alt="cambiar" onClick={()=>updateUser(user)} className="profile__actualiza" /> 
        </Grid>
        <p className="profile__subtitle">Dirección favorita para que recojamos</p>
        <Grid container direction="row">
          <InputBase
            placeholder="Dirección favorita para que recojamos"
            className="profile__input"
            value={user?.address}
            onChange={(address) =>
              setUser({...user, address: address.target.value})
            }
          />
          <img src={change} alt="cambiar" onClick={()=>updateUser(user)} className="profile__actualiza"/> 
        </Grid>
        <p className="profile__subtitle" style={{marginBottom:"8px"}}>Ciudad</p>
        <Grid container direction="row">
          <Select
            className="profile__select"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={user === undefined ? "" : user.city}
            onChange={(city) =>
              setUser({...user, city: city.target.value})
            }
          >
            <MenuItem value={"Bogota"}>Bogotá</MenuItem>
            <MenuItem value={"Villavicencio"}>Villavicencio</MenuItem>
          </Select>
          <img className="profile__actualiza" src={change} alt="cambiar" onClick={()=>updateUser(user)}  /> 
        </Grid>
        <p className="profile__subtitle">Contraseña</p>
        <Grid container direction="row">
          <InputBase
            placeholder="Contraseña"
            className="profile__input"
            // onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
            //   setNombre((e.target as HTMLSelectElement).value)
            // }
          />
          <img src={change} alt="cambiar" onClick={()=>updateUser(user)} className="profile__actualiza" /> 
        </Grid>
      </>
      )
    case 1:
      return(
        <>
          <Grid container direction="row" alignItems="center">
            <p className="profile__secondtitle">Entregas en curso</p>
            <img src={entregapersona} alt="entregapersona" style={{marginLeft:"16px"}} /> 
            <img src={boxtime} alt="boxtime" style={{marginLeft:"4px"}}  /> 
          </Grid>
          <WasteETable 
            data={entregaCurso} 
            setData={setEntregaCurso}
            columns={[{
              label:"Fecha de recolección",
              sort: true,
              property: "fechaRecoger",
              type:"date",
            },{
              label:"Dispositivo",
              sort: true,
              property: "dispositivo",
            },{
              label:"Estado",
              sort: true,
              property: "estado",
            },{
              label: "Visualizar",
              image: lupa,
              onClick: entregaCursoClick,
            }
          ]}/>
        </>
      )
      case 2:
        return(
          <>
            <Grid container direction="row" alignItems="center">
              <p className="profile__secondtitle">Entregas finalizadas</p>
            </Grid>
            <WasteETable 
              data={entregaFinal} 
              setData={setEntregaFinal}
              columns={[{
                label:"Fecha de recolección",
                sort: true,
                property: "fechaRecoger",
                type:"date",
              },{
                label:"Dispositivo",
                sort: true,
                property: "dispositivo",
              },{
                label:"Estado",
                sort: true,
                property: "estado",
              },{
                label: "Foto",
                image: lupa,
                onClick: ()=>setStep(0),
              }]}
            />
          </>
        )
        case 3:
          return(
            <>
              <Grid container direction="row" alignItems="center">
                <p className="profile__secondtitle">Entregas en curso</p>
                <img src={entregapersona} alt="entregapersona" style={{marginLeft:"16px"}} /> 
                <img src={boxtime} alt="boxtime" style={{marginLeft:"4px"}}  /> 
              </Grid>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={8}>
                <table className="profile__table">
                  <tbody>
                    <tr>
                      <td className="profile__table--gray profile__table--font">Ciudad</td>
                      <td className="profile__table--font">
                        {entrega.ciudad}
                      </td>
                    </tr>
                    <tr>
                      <td className="profile__table--gray profile__table--font">Fecha a recoger</td>
                      <td className="profile__table--font">
                        {formatDate(entrega.fecha)}  
                      </td>
                    </tr>
                    <tr>
                      <td className="profile__table--gray profile__table--font">Dirección</td>
                      <td className="profile__table--font">
                        {entrega.direccion}
                      </td>
                    </tr>
                    <tr>
                      <td className="profile__table--gray profile__table--font">Dispositivo</td>
                      <td className="profile__table--font">
                        {entrega.dispositivo}
                      </td>
                    </tr>
                    <tr>
                      <td className="profile__table--gray profile__table--font">Dimensiones</td>
                      <td className="profile__table--font">
                        {entrega.dimensiones}
                      </td>
                    </tr>
                    <tr>
                      <td className="profile__table--gray profile__table--font">Horario para recoger</td>
                      <td className="profile__table--font">
                        {entrega.horario}
                      </td>
                    </tr>
                    <tr>
                      <td className="profile__table--gray profile__table--font">Foto</td>
                      <td>
                        <div className="profile__table--photos">
                          <ShowImgs images={entrega.images}/>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={1} container />                  
              <Grid item xs={3} container alignItems="center" direction="column">                  
                <p className="profile__collectortitle">Tu recolector:</p>
                <img className="profile__photo" src={operadorImg} alt="operador" /> 
                <p className="profile__collectorname">{entrega.operador.nombre}</p>
              </Grid>
            </Grid>
            </>
          )
  }
  return (<>Not Supported</>)

  
}

function Profile({defStep}: any) {
  const history = useHistory();
  const {user, setUser} = useContext(MainContext);
  const userCollection = useFirestore().collection('user');
  const [step, setStep] = useState(defStep ? defStep : 0);
  //const [profileImg, setProfileImg] = useState(defaultProfImg);


  if(localStorage.userid && user === undefined){
    userCollection.doc(localStorage.userid).get().then((userResult)=>{
      // console.log("user");
      // console.log(user);
      setUser(userResult.data());
    });
  }
  if(localStorage.userid === undefined){
    setUser(undefined);
    history.push("/");
  }

  async function updateUser(newUser: any){
    try{ 
      // Get current User
      let userInfo = await userCollection.doc(localStorage.userid).get();
      // console.log("user info photo");
      // console.log(userInfo.data());
      if(userInfo === undefined || user === undefined) return;

      // Update
      // console.log("updating user");
      // console.log(newUser);
      await userInfo.ref.update(newUser);
      
    }catch(err){
      console.log(err);
    }
  }


  async function changeImg(file: File){

    const fileName = getFileName(file.name);

    if(user === undefined) return;

    try{ 
      // Save image
      const storageRef = firebase.storage().ref();
      let child = storageRef.child('profile/' + fileName);
      await child.put(file);
      const url = await child.getDownloadURL();

      const lastPhoto = user.photo;
      // Update
      setUser({...user, photo: fileName, photoUrl: url});
      updateUser({photo: fileName});

      // Remove last image
      const lastRef = firebase.storage().ref('profile/' + lastPhoto);
      await lastRef.delete();
    
    }catch(err){
      console.log(err);
    }
  }

  // useEffect(() => {
  //   console.log(status);
  //   // eslint-disable-next-line
  // }, [status]);
  
  return (
    <>
      <Grid container alignItems="flex-start" justify="center" style={{padding:"80px 70px"}}>
        <Grid container item direction="column" style={{height:"800px"}} xs={4} >
          <ProfileBase 
            buttonLabel="Programa una nueva entrega"
            image={user?.photoUrl}
            name={user ? user.name : ""}
            buttonOnClick={()=>history.push("/form")}
            onEditImage={changeImg}
            title={step === 0 ? "Tu perfil" : ""}
          />
        </Grid>
        <Grid container item direction="column" style={{paddingLeft:"64px"}} xs={8}>
          <Current step={step} setStep={setStep} user={user} setUser={setUser} updateUser={updateUser}/>
        </Grid>
      </Grid>
    </>
  );
}

export default Profile;
