import React, {useState, useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {useFirestore} from 'reactfire';
import * as firebase from 'firebase';

import WasteETable from '../../../components/wasteETable/wasteETable';
import PersonalizedInput from '../../../components/personalizedInput/personalizedInput';

import bloquear from '../../../assets/icons/obstruido 1.svg';
import cross from '../../../assets/icons/cross red.svg';

import './adminUsuarios.scss';
import { useHistory } from 'react-router-dom';

function AdminUsuarios() {
  let _useFirestore = useFirestore();
  let history = useHistory();

  const [usuarios, setUsuarios] = useState([]);
  const [usuariosFilter, setUsuariosFilter] = useState([]);

  useEffect(() => {
    index();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const index = () =>
    _useFirestore
      .collection('user')
      .get()
      .then((result) => {
        let data: any = [];
        result.forEach((doc: any) => {
          let item = doc.data();
          data.push({...item, id: doc.id});
        });
        setUsuarios(data);
        setUsuariosFilter(data);
      });

  const edit = (id: string, estado: any) => {
    _useFirestore.collection('user').doc(id).set(
      {
        estado,
      },
      {merge: true}
    );
    index();
  };

  const _remove = (id: string) => {
    let _confirm: boolean = window.confirm('¿Desea eliminar?');
    if (_confirm) {
      var user : any = firebase.auth().currentUser;
      user
        .delete()
        .then(function () {
          _useFirestore.collection('user').doc(id).delete();
        })
        .catch( (error: any) => {});
      index();
    }
  };

  const search = (value: any) => {
    let data: any = usuarios;
    if (value) {
      let items: any = data.filter((e: any) =>
        e.name.toLowerCase().includes(value.toLowerCase())
      );
      setUsuarios(items);
    } else {
      setUsuarios(usuariosFilter);
    }
  };

  return (
    <div className="adminUsuarios">
      <Grid container justify="space-between" alignItems="center">
        <p className="title-5">Gestión de usuarios:</p>
        <div className="green-button" onClick={()=>history.push("/admin/home")}>Menú principal</div>
      </Grid>
      <PersonalizedInput placeholder="Busca aqui..." setValue={search} />

      <Grid container justify="space-between" alignItems="center">
        <p className="title-5">Usuarios:</p>
      </Grid>

      <WasteETable
        data={usuarios}
        setData={setUsuarios}
        columns={[
          {
            label: 'Nit',
            sort: true,
            property: 'nit',
          },
          {
            label: 'Nombre',
            sort: true,
            property: 'name',
          },
          {
            label: 'Correo',
            sort: true,
            property: 'email',
          },
          {
            label: 'Ciudad',
            sort: true,
            property: 'city',
          },
          {
            label: 'Dirección',
            sort: true,
            property: 'address',
          },
          {
            label: 'Teléfono',
            sort: true,
            property: 'cellphone',
          },
          {
            label: 'Estado',
            sort: true,
            property: 'state',
          },
          {
            label: 'Bloquear',
            image: bloquear,
            onClick: (e: any) => {
              edit(e.id, e.estado === 'ACTIVO' ? 'INACTIVO' : 'ACTIVO');
            },
          },
          {
            label: 'Eliminar',
            image: cross,
            onClick: (e: any) => {
              _remove(e.id);
            },
          },
        ]}
      />
    </div>
  );
}

export default AdminUsuarios;
