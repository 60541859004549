import React, { FC } from 'react';
import './personalizedInput.scss';

import buscarIcon from '../../assets/icons/buscar 1.svg';

interface Props{
  value?: any;
  setValue?: any;
  placeholder?: string;
}

const PersonalizedInput: FC<Props> = ({ value, setValue, placeholder}) => {

  return (
  <input 
    className="personalizedInput" 
    placeholder={placeholder}
    style={{
      backgroundImage: `url('${buscarIcon}')`,
    }}
    value={value}
    onChange={(event)=>setValue(event.target.value)}
  />);
}

export default PersonalizedInput;