import React, { useEffect, useState } from 'react';
import './adminEntregaCoordinar.scss';
import { Grid, Select, MenuItem } from '@material-ui/core';
import BackButton from '../../../components/backButton/backButton';
import ShowImgs from '../../../components/showImgs/ShowImgs';

import * as firebase from 'firebase';

import { useParams } from "react-router-dom";
import { useHistory } from 'react-router'
import { useFirestore } from 'reactfire';
import { formatDate } from '../../../services/services';

function AdminEntregaCoordinar(){

  const [entrega, setEntrega] = useState<any>({
    nombre: '',
    ciudad: '',
    fecha: '',
    direccion: '',
    dispositivo: '',
    dimensiones: [],
    horario: '',
    foto: [],
    reciclador: ''
  });

  const [state, setState] = useState('END');

  const [ recicladores, setRecicladores] = useState<any[]>([]);

  const { entregaid } = useParams<any>();
  const _useFirestore = useFirestore();
  const history = useHistory()

  async function getIdEntrega(id: string){
    try{
      return await _useFirestore.collection('entrega').doc(id).get();
    }catch(err){
      console.log(err)
      history.push('/admin/home')
    }
  }

  async function getEntrega(){
    const entregaRes = await getIdEntrega(entregaid);
    const entregaData = entregaRes?.data();
    const userRes = await _useFirestore.collection('user').doc(entregaData?.userid).get();
    const userData = userRes.data();
    let reciclador = ''
    if(entregaData?.reciclador){
      const recicladorRes = await _useFirestore.collection('reciclador').doc(entregaData?.reciclador).get();
      const recicladorData = recicladorRes.data();
      reciclador = recicladorData?.nombre;
    }
    
    const dimensions = entregaData?.productList.map((el:any, key:any)=><p key={key}>{el.type + ':' + el.transporte}</p>);

    const storage = firebase.storage();
    const photos = [];

    for(const prod of entregaData?.productList){
      const storageRef = await storage.ref('orders/' + prod.photo);
      photos.push(await storageRef.getDownloadURL())
    }

    setEntrega({
      nombre: userData?.name + (userData?.apellido ? userData?.apellido: "" ),
      ciudad: entregaData?.city,
      fecha: formatDate(new Date(entregaData?.date * 1000)),
      direccion: entregaData?.address,
      dispositivo: entregaData?.productList.map((el:any)=>el.type).join(', '),
      dimensiones: dimensions,
      horario: entregaData?.franja,
      foto: photos,
      reciclador: reciclador
    })

    
    setState(entregaData?.state);

  }

  async function getRecicladores() {
    const recicladores = await _useFirestore.collection('reciclador').where('estado', '==', 'ACTIVO').get();
    const recicladoresData = [];

    for(const reciclador of recicladores.docs){
      recicladoresData.push({
        ...reciclador.data(),
        recicladorid: reciclador.id,
      })
    }

    setRecicladores(recicladoresData);

  }

  useEffect(()=>{
    getEntrega();
    getRecicladores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onSubmit(){
    if(entrega.reciclador === '' || entrega.reciclador === undefined) return;
    console.log(entrega.reciclador)
    const entregaRes = await getIdEntrega(entregaid);
    await entregaRes?.ref.update({reciclador: entrega.reciclador, state: 'EN CURSO'})

    history.push('/admin/entregas/gestion')
  }


  async function onFinishEntrega(){
    const entregaRes = await getIdEntrega(entregaid);
    await entregaRes?.ref.update({state: 'END'})
    history.push('/admin/entregas/gestion')

  }

  function clickBackButton(){
    history.push('/admin/entregas/gestion')
  }

  return (
    <div className="adminEntregaCoordinar">
      <Grid container justify="space-between" alignItems="center">
        <p className="title-5">Coordina la entrega:</p>
        <div className="green-button" onClick={()=>history.push("/admin/home")}>Menú principal</div>
      </Grid>

      <table className="adminEntregaCoordinar__table">
        <tbody>
          <tr>
            <td className="adminEntregaCoordinar__table--gray adminEntregaCoordinar__table--font">Nombre de usuario</td>
            <td className="adminEntregaCoordinar__table--font">
              {entrega.nombre}
            </td>
          </tr>
          <tr>
            <td className="adminEntregaCoordinar__table--gray adminEntregaCoordinar__table--font">Ciudad</td>
            <td className="adminEntregaCoordinar__table--font">
              {entrega.ciudad}
            </td>
          </tr>
          <tr>
            <td className="adminEntregaCoordinar__table--gray adminEntregaCoordinar__table--font">Fecha a recoger</td>
            <td className="adminEntregaCoordinar__table--font">
              {entrega.fecha}
            </td>
          </tr>
          <tr>
            <td className="adminEntregaCoordinar__table--gray adminEntregaCoordinar__table--font">Dirección</td>
            <td className="adminEntregaCoordinar__table--font">
              {entrega.direccion}
            </td>
          </tr>
          <tr>
            <td className="adminEntregaCoordinar__table--gray adminEntregaCoordinar__table--font">Dispositivo</td>
            <td className="adminEntregaCoordinar__table--font">
              {entrega.dispositivo}
            </td>
          </tr>
          <tr>
            <td className="adminEntregaCoordinar__table--gray adminEntregaCoordinar__table--font">Dimensiones</td>
            <td className="adminEntregaCoordinar__table--font">
              {entrega.dimensiones}
            </td>
          </tr>
          <tr>
            <td className="adminEntregaCoordinar__table--gray adminEntregaCoordinar__table--font">Horario para recoger</td>
            <td className="adminEntregaCoordinar__table--font">
              {entrega.horario}
            </td>
          </tr>
          <tr>
            <td className="adminEntregaCoordinar__table--gray adminEntregaCoordinar__table--font">Foto</td>
            <td>
              <div className="adminEntregaCoordinar__table--photos">
                <ShowImgs images={entrega.foto}/>
              </div>
            </td>
          </tr>
          {(state !== 'NEW') && (
            <tr>
              <td className="adminEntregaCoordinar__table--gray adminEntregaCoordinar__table--font">Nombre del reciclador</td>
              <td className="adminEntregaCoordinar__table--font">
                {entrega.reciclador}          
              </td>
            </tr>
          )}
        </tbody>
      </table>
    
      {(state === 'NEW') && (
        <table className="adminEntregaCoordinar__table" style={{marginTop: 30}}>
          <tbody>
            <td className="adminEntregaCoordinar__table--gray adminEntregaCoordinar__table--font">Nombre del reciclador</td>
            <td className="adminEntregaCoordinar__table--font">
              <div className="adminEntregaCoordinar__input ">
                <Select
                  value={entrega.reciclador}
                  onChange={(event) => setEntrega({...entrega, reciclador: event.target.value})}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Nombre del reciclador
                  </MenuItem>
                  {recicladores.map((item, index) => (
                    <MenuItem value={item.recicladorid} key={index}>{item.nombre}</MenuItem>
                  ))}
                </Select>
              </div>
              
            </td>
          </tbody>
        </table>
      )} 

      <Grid container justify="space-between" alignItems="center" style={{marginTop: 20}}>
        <BackButton onClick={clickBackButton}/>

        {state === 'NEW' && <div
          className="adminEntregaCoordinar__submit-button"
          onClick={onSubmit}
        >
          Guardar los cambios
        </div>}

        {state === 'EN CURSO' && <div
          className="adminEntregaCoordinar__finish-entrega"
          onClick={onFinishEntrega}
        >
          Confirmar entrega <br/> finalizada
        </div>}
      </Grid>


   </div>
  );
}

export default AdminEntregaCoordinar;