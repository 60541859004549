import React from 'react';
import './profileBase.scss';
import change from '../../assets/icons/intercambio 1.svg';

import ImageInput from '../imageInput/ImageInput'

interface Props{
  image: any;
  name: string;
  onEditImage?: (file: File) => any; // Undefined for no edit
  buttonLabel: string;
  buttonOnClick: any;
  title: string;
}

function ProfileBase({image, name, onEditImage, buttonLabel, buttonOnClick, title}: Props) {

  return (
    <div className="profileBase">
      {title && (<p className="profileBase__title">{title}</p>)}
      <div className="profileBase__img--prof">
        <div className="profileBase__img--container">
          {onEditImage && (
            <div className="profileBase__img--change" >
              <ImageInput
                onEditImage={onEditImage}
              >
                <img src={change} alt="change"></img>
              </ImageInput>
            </div>
          )}
          <img src={image} alt="profile" className="profileBase__photo" />
        </div>
        <p className="profileBase__titlename">{name}</p>
      </div>
      <div className="profileBase__inputnew" onClick={buttonOnClick}>{buttonLabel}</div>
    </div>
  );
}

export default ProfileBase;