import React  from 'react';
import './adminHome.scss';
import { Grid } from '@material-ui/core';
import logo from '../../../assets/wastelogo.svg';
import { useHistory } from 'react-router-dom';




function AdminHome(){
  const history = useHistory();
  const buttons = [
    {title: "Creación de usuario", onClick:()=>history.push("/admin/creationCliente")},
    {title: "Gestión de usuarios", onClick:()=>history.push("/admin/usuarios")},
    {title: "Nuevas entregas", onClick:()=>history.push("/admin/entregas/gestion")},
    {title: "Entregas en curso", onClick:()=>{history.push("/admin/entregas/curse")}},
    {title: "Entregas terminadas", onClick:()=>{history.push("/admin/entregas/end")}},
    {title: "Gestión de recicladores", onClick:()=>history.push("/admin/recicladores")},
  ]

  return (
    <Grid className="adminHome" container justify="space-around" style={{marginTop:"-16px", paddingTop:"0px"}}>
      <Grid item xs={8} container direction="column" className="adminHome__section">
        <p className="title-1 adminHome__admin-title">Administrador</p>
        <div className="adminHome__box-container">
          {buttons.map((item,key) => (
            <div key={key} className="adminHome__box mt-2 mr-2 title-5" onClick={item.onClick}>
              {item.title}
            </div>
            ))
          }
        </div>
      </Grid>
      <Grid 
        container 
        justify="center" 
        alignItems="center" 
        direction="column" item 
        xs={4} 
        className="adminHome__section adminHome__section--right"
      >
        <p className="title-1 adminHome__welcome">Bienvenido <br/> a</p>
        <img className="adminHome__logo" src={logo} alt="WAST-E logo"/>
      </Grid>
    </Grid>
  );
}

export default AdminHome;