import React, { useState }  from 'react';
import { Grid,  InputBase } from '@material-ui/core';

// scss
import './contactenos.scss';

// assets
import logo from '../../../assets/wastelogo.svg';
import { useFirestore } from 'reactfire';


function Contactenos() {


  let _useFirestore = useFirestore();

  const saveContact = (data:any) => {
    _useFirestore.collection('contact').add(data);
    // .
    // then(
    //   ()=>console.log("Se envio correcto")).
    // catch(
    //   ()=>console.log("no se envio"));
  };

  const [contact, setContact] = useState({
    name:'',
    email:'',
    number:'',
    message:''
  });
  // console.log(contact);

  return (
    <>
      <Grid container direction="row" alignItems="center" justify="center" style={{marginBottom:"80px"}} >
        <Grid item container direction="column" alignItems="center" justify="center" xs={6}>
          <p className="contactenos__text">
            Quéremos saber de ti: <br /><br /> Escríbenos tu mensaje y lo mas pronto posible nos pondremos en contacto contigo
          </p>
          <img src={logo} className="login__logo" alt="logo waste"></img>
        </Grid>
        <Grid item container direction="column" alignItems="flex-start" justify="center" xs={6}>
          <Grid style={{marginLeft:"100px", width:"400px"}}>
            <InputBase
              placeholder="Tu nombre"
              className="contactenos__input"
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>{
                  let name = (e.target as HTMLSelectElement).value;
                  setContact({...contact, name});
                }
              }
            />
            <InputBase
              placeholder="Tu correo eletrónico"
              className="contactenos__input"
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>{
                let email = (e.target as HTMLSelectElement).value;
                setContact({...contact, email});
              }
            }
            />
            <InputBase
              placeholder="Tu número de celular"
              className="contactenos__input"
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>{
                let number = (e.target as HTMLSelectElement).value;
                setContact({...contact, number});
              }
            }
            />
            <textarea
              placeholder="Tu mensaje aqui..."
              className="contactenos__input--comment"
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>{
                let message = (e.target as HTMLSelectElement).value;
                setContact({...contact, message});
              }
            }
            />
            <Grid container justify="flex-end" style={{marginRight:"200px"}} alignItems="center" onClick={()=>saveContact(contact)}>
              <div className="green-button contactenos__button">Enviar</div>
            </Grid>
          </Grid>
          
        </Grid>
      </Grid>
    </>
  );
}

export default Contactenos;