import React, { useContext, useState, useEffect, useRef } from "react";
import logo from "../../assets/wastelogo.svg";
import "./navbar.scss";
import { Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../context/MainContext";

import greenCircle from "../../assets/icons/greenCircle.svg";
import triangle from "../../assets/icons/triangle-bottom.svg";

import entrega from "../../assets/entrega.svg";

function Navbar() {
  const history = useHistory();
  const { user, productList } = useContext(MainContext);
  const [open, setOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const wrapperRef = useRef(null);

  async function deleteSession() {
    try {
      localStorage.removeItem("userid");
    } catch (error) {
      console.log(error);
    }
  }

  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      // Alert if clicked on outside of element
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className="navbar"
      alignContent="center"
    >
      <Grid item className="header">
        <Grid className="ml-5 navbar__logo">
          <img src={logo} alt="logo" onClick={() => history.push("/")} />
        </Grid>
        <Grid className="ml-5 navbar__title" onClick={() => history.push("/")}>
          Inicio
        </Grid>
      </Grid>
      <Grid item className="header">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          alignContent="center"
        >
          <Grid item>
            <Grid
              className="mr-5 navbar__title"
              onClick={() => history.push("/nosotros")}
            >
              Nosotros
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              className="mr-5 navbar__title"
              onClick={() => history.push("/contactenos")}
            >
              Contactenos
            </Grid>
          </Grid>
          <Grid
            item
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
            style={{ position: "relative" }}
          >
            <Grid className="mr-5 navbar__title">Más</Grid>
            {showMenu ? (
              <Grid className="submenu_container">
                <Grid
                  className="submenu"
                  onMouseEnter={() => setShowSubMenu(true)}
                  onMouseLeave={() => setShowSubMenu(false)}
                >
                  Reportes de gestión
                  {showSubMenu ? (
                    <a
                      href="http://wast-e.co/InformeSostenibilidad2023.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecorationLine: "unset" }}
                    >
                      <Grid className="submenu2">Reporte gestión BIC 2023</Grid>
                    </a>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          {localStorage.userid ? (
            <Grid item ref={wrapperRef}>
              <Grid
                className="mr-5 navbar__title"
                container
                alignItems="center"
                onClick={() => setOpen(!open)}
              >
                <img
                  src={user?.photoUrl}
                  alt="perfil"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    marginRight: "16px",
                  }}
                />
                Hola, {user ? user.name.split(" ")[0] : ""}
                <img
                  src={triangle}
                  alt="perfil"
                  style={{ marginLeft: "16px", marginTop: "5px" }}
                />
                <img
                  src={entrega}
                  alt="perfil"
                  style={{ width: "50px", height: "50px", marginLeft: "16px" }}
                />
                <img
                  src={greenCircle}
                  alt="perfil"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "8px",
                    marginTop: "-35px",
                  }}
                />
                <p className="navbar__count">{productList.length}</p>
              </Grid>
              {open ? (
                <Grid container direction="column" className="navbar__menu">
                  <p
                    className="navbar__menu--black"
                    onClick={() => {
                      setOpen(false);
                      history.push("/form");
                    }}
                  >
                    Inicio
                  </p>
                  <p
                    className="navbar__menu--black"
                    onClick={() => {
                      setOpen(false);
                      history.push("/profile");
                    }}
                  >
                    Mi perfil
                  </p>
                  <p
                    className="navbar__menu--black"
                    onClick={() => {
                      setOpen(false);
                      history.push("/profile/encurso");
                    }}
                  >
                    Entregas en curso
                  </p>
                  <p
                    className="navbar__menu--black"
                    onClick={() => {
                      setOpen(false);
                      history.push("/profile/finalizadas");
                    }}
                  >
                    Entregas en finalizadas
                  </p>
                  <p
                    className="navbar__menu--red"
                    onClick={async () => {
                      await deleteSession();
                      setOpen(false);
                      history.push("/");
                    }}
                  >
                    Cerrar sesión
                  </p>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          ) : (
            <Grid item className="navbar__register--margin">
              <Button
                className="navbar__register"
                variant="contained"
                onClick={() => history.push("/login")}
              >
                Ingresa a nuestra comunidad
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Navbar;
