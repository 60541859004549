import React, {
  createContext,
  useState,
  FunctionComponent,
  useEffect
} from "react";

import { useFirestore } from 'reactfire';
import * as firebase from 'firebase';
import defaultProfImg from '../assets/icons/defaultUser.png';


interface user {
  userid: string;
  name: string;
  email: string;
  address: string;
  city: string;
  photo: string;
  photoUrl?: string;
  role: string; // USER, ADMIN
}

interface state {
  productList: any[];
  setProductList: any;
  user?: user;
  setUser: any;
}

export const MainContext = createContext<state>({
  productList: [],
  user: undefined,
  setProductList: function(){},
  setUser: function(){},
});

const MainProvider: FunctionComponent = (props: any) => {

  const [productList, setProductList] = useState<any[]>([]);
  const [user, setUser] = useState<user>();


  async function loadImg(photoStr: string){
    const storage = firebase.storage();
    const storageRef = storage.ref('profile/' + photoStr);
    return await storageRef.getDownloadURL();
  }

  let _useFirestore = useFirestore();


  useEffect(()=>{
    if(localStorage.userid === undefined) setUser(undefined);
    else{
      _useFirestore.collection('user').doc(localStorage.userid).get().then(async (userResult: any)=>{
        const saveUser = userResult.data();
        if(saveUser.photo){
          const img = await loadImg(saveUser.photo)
          saveUser.photoUrl = img;
        }else saveUser.photoUrl = defaultProfImg;
        
        setUser(saveUser);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.userid])

  

  return (
    <MainContext.Provider value={{ productList, setProductList, user, setUser}}>
      {props.children}
    </MainContext.Provider>
  );
};

export default MainProvider;