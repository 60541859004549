import React, { useState } from 'react';
import './ShowImgs.scss';

import Modal from '@material-ui/core/Modal';

import arrow from '../../assets/icons/right-arrow.svg';
import eye from '../../assets/icons/eye.svg';

interface Props{
  images: string[]
}

function ShowImgs({images}: Props) {

  const [i, setI] = useState(0);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <img className="showImgs__eye" src={eye} onClick={()=>setOpen(true)} alt=""/>
      <Modal
        open={open}
        onClose={()=>setOpen(false)}
        className="showImgs"
      >
        <>
          {(i > 0) && <img src={arrow} alt="" className="showImgs__arrow showImgs__arrow--left" onClick={()=>setI(i - 1)}/>}
          <img src={images[i]} alt="" className="showImgs__img"/>
          {(i < images.length - 1) && <img src={arrow} alt="" className="showImgs__arrow showImgs__arrow--rigth" onClick={()=>setI(i + 1)}/>}
        </>
      </Modal>

    </div>
    
  );
}

export default ShowImgs;