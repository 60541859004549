import React, {useState, useContext, useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {useFirestore} from 'reactfire';


// componentes
import SpecialCombobox from '../../../components/SpecialCombobox/specialCombobox';
import ImageInput from '../../../components/imageInput/ImageInput';


import { MainContext } from '../../../context/MainContext'

// scss
import './form.scss';

// assets
import apilar from '../../../assets/items/apilar.svg';
import camara from '../../../assets/items/camara.svg';
import celular from '../../../assets/items/celular.svg';
import impresora from '../../../assets/items/impresora.svg';
import lan from '../../../assets/items/lan.svg';
import laptop from '../../../assets/items/laptop.svg';
import pc from '../../../assets/items/pc.svg';
import perifericos from '../../../assets/items/perifericos.svg';
import estado from '../../../assets/Combobox icons/estado.svg';
import marca from '../../../assets/Combobox icons/marca.svg';
import transporte from '../../../assets/Combobox icons/transporte.svg';
import peso from '../../../assets/Combobox icons/peso.svg';
import BackButton from '../../../components/backButton/backButton';
import { useHistory } from 'react-router-dom';


const MARCAS = {
  "Computador portátil":[{
      value:"HP",
      label:"HP",
    },{
      value:"Acer",
      label:"Acer",
    },{
      value:"ASUS",
      label:"ASUS",
    },{
      value:"Lenovo",
      label:"Lenovo",
    },{
      value:"Dell",
      label:"Dell",
    },{
      value:"Microsoft",
      label:"Microsoft",
    },{
      value:"Mac",
      label:"Mac",
    },{
      value:"LG",
      label:"LG",
    },{
      value:"Vaio",
      label:"Vaio",
    },{
      value:"Sony",
      label:"Sony",
    },{
      value:"Otros",
      label:"Otros",
    }],
  "Celular":[{
      value:"Samsung",
      label:"Samsung",
    },{
      value:"Xiaomi",
      label:"Xiaomi",
    },{
      value:"Huawei",
      label:"Huawei",
    },{
      value:"Motorola",
      label:"Motorola",
    },{
      value:"Samsung",
      label:"Samsung",
    },{
      value:"Blackberry",
      label:"Blackberry",
    },{
      value:"Iphone",
      label:"Iphone",
    },{
      value:"LG",
      label:"LG",
    },{
      value:"Redmi",
      label:"Redmi",
    },{
      value:"Nokia",
      label:"Nokia",
    },{
      value:"Sony",
      label:"Sony",
    },{
      value:"Otros",
      label:"Otros",
    }],
  "Cámara":[{
      value:"Canon",
      label:"Canon",
    },{
      value:"Fujifilm",
      label:"Fujifilm",
    },{
      value:"Microsoft",
      label:"Microsoft",
    },{
      value:"Panasonic",
      label:"Panasonic",
    },{
      value:"Kodak",
      label:"Kodak",
    },{
      value:"Sony",
      label:"Sony",
    },{
      value:"Welcam",
      label:"Welcam",
    },{
      value:"Tp-Link",
      label:"Tp-Link",
    },{
      value:"Ezviz",
      label:"Ezviz",
    },{
      value:"Otros",
      label:"Otros",
    },],
  "Impresora":[{
      value:"HP",
      label:"HP",
    },{
      value:"Canon",
      label:"Canon",
    },{
      value:"Epson",
      label:"Epson",
    },{
      value:"Lexmark",
      label:"Lexmark",
    },{
      value:"Kodak",
      label:"Kodak",
    },{
      value:"Xerox",
      label:"Xerox",
    },{
      value:"Pantum",
      label:"Pantum",
    },{
      value:"Dymo",
      label:"Dymo",
    },{
      value:"Zebra",
      label:"Zebra",
    },{
      value:"Brother",
      label:"Brother",
    },{
      value:"Samsung",
      label:"Samsung",
    },{
      value:"Polaroid",
      label:"Polaroid",
    },{
      value:"Dell",
      label:"Dell",
    },{
      value:"Otros",
      label:"Otros",
    }],
  "Computador de mesa":[{
      value:"HP",
      label:"HP",
    },{
      value:"Acer",
      label:"Acer",
    },{
      value:"ASUS",
      label:"ASUS",
    },{
      value:"Lenovo",
      label:"Lenovo",
    },{
      value:"Dell",
      label:"Dell",
    },{
      value:"Microsoft",
      label:"Microsoft",
    },{
      value:"Mac",
      label:"Mac",
    },{
      value:"LG",
      label:"LG",
    },{
      value:"Vaio",
      label:"Vaio",
    },{
      value:"Sony",
      label:"Sony",
    },{
      value:"Toshiba",
      label:"Toshiba",
    },{
      value:"Armado por ti",
      label:"Armado por ti",
    },{
      value:"Piezas",
      label:"Piezas",
    },{
      value:"Otros",
      label:"Otros",
    }],
  "Cables":[{
      value:"Otros",
      label:"Otros",
    }],  
  "Periféricos":[{
      value:"Microsoft",
      label:"Microsoft",
    },{
      value:"Logitech",
      label:"Logitech",
    },{
      value:"Genius",
      label:"Genius",
    },{
      value:"RedDragon",
      label:"RedDragon",
    },{
      value:"Trust",
      label:"Trust",
    },{
      value:"Corsair",
      label:"Corsair",
    },{
      value:"Otros",
      label:"Otros",
    }], 
}


function DevicePhoto({onClick}:any){
  return (
    <div className="form__combobox-container">
      <ImageInput
        onEditImage={onClick}
      >
        <Grid className="form__disp" container direction="row" alignItems="center" justify="flex-start">
          <img src={camara} alt="laptop_icon" className="form__disp--icon" />
          <p className="form__disp--text">¿Quieres subir una foto de tu dispositivo?</p>
        </Grid>
      </ImageInput>
    </div>
    
  )
}

function FirstStep({
  items1,
  items2,
}: any) {
  return (
    <Grid container alignItems="center" justify="center">
      <p className="form__title">¿Qué deseas desechar hoy?</p>
      <Grid container alignItems="flex-start" justify="space-around" direction="row">
        <Grid item container alignItems="flex-start" justify="space-between" direction="column" xs={11} md={5}>
          {items1.map((item:any,key:any)=>(
            <Grid key={key} onClick={item.onClick} className="form__item" container direction="row" alignItems="center" justify="flex-start">
              <img src={item.src} alt="laptop_icon" className="form__item--icon" />
              <p className="form__item--text">{item.name}</p>
            </Grid>
          ))}
        </Grid>
        <Grid item container alignItems="flex-start" justify="space-between" direction="column" xs={11} md={5}>
          {items2.map((item:any,key:any)=>(
            <Grid key={key} onClick={item.onClick} className="form__item" container direction="row" alignItems="center" justify="flex-start">
              <img src={item.src} alt="laptop_icon" className="form__item--icon" />
              <p className="form__item--text">{item.name}</p>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
function SecondStep({history, onBack, item, setItem, firebase}:any) {

  let marcas: {value: string; label: string;}[] = [];
  switch(item.type){
    case "Cables": marcas = MARCAS.Cables; break;
    case "Celular": marcas = MARCAS.Celular; break;
    case "Computador de mesa": marcas = MARCAS['Computador de mesa']; break;
    case "Computador portátil": marcas = MARCAS['Computador portátil']; break;
    case "Cámara": marcas = MARCAS.Cámara; break;
    case "Impresora": marcas = MARCAS.Impresora; break;
    case "Periféricos": marcas = MARCAS.Periféricos; break;
  }

  // Devices for map
  const dispo = [{ 
      currentvalue: item.marca,
      src:marca,
      option:marcas,
      onClick:({label}:any)=>{setItem({...item,marca:label})}
    },{ 
      currentvalue: item.estado,
      src:estado,
      option:[{
        value:"Para reparación",
        label:"Para reparación"
      },{
        value:"Problemas estéticos",
        label:"Problemas estéticos"
      },{
        value:"No funciona",
        label:"No funciona"
      },{
        value:"Funcional",
        label:"Funcional"
      }],
      onClick:({label}:any)=>{setItem({...item,estado:label})}
    },{ currentvalue: item.transporte,
      src:transporte,
      option:[{
        value:"La mano",
        label:"La mano"
      },{
        value:"Una maleta",
        label:"Una maleta"
      },{
        value:"Una carreta",
        label:"Una carreta"
      },{
        value:"Un carro pequeño",
        label:"Un carro pequeño"
      },{
        value:"Un camion",
        label:"Un camión"
      }
    ],
    onClick:({label}:any)=>{setItem({...item,transporte:label})}
  }]

  const [validation, setValidation] = useState<any>({
    marca: {
      state: true,
      message: "Elige una marca",
    },
    estado: {
      state: true,
      message: "Elige el estado de tu dispositivo",
    },
    transporte: {
      state: true,
      message: "Elige como podemos llevarlo",
    },
  });
  
  useEffect(() => {
    if(item.marca !== "Marca") setValidation({...validation, marca: {...validation.marca, state:true}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.marca]);

  useEffect(() => {
    if(item.estado !== "Estado de tu dispositivo") setValidation({...validation, estado: {...validation.marca, state:true}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.estado]);

  useEffect(() => {
    if(item.transporte !== "¿Cómo podemos llevarlo?") setValidation({...validation, transporte: {...validation.transporte, state:true}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.transporte]);

  const {productList, setProductList} = useContext(MainContext);
  
  return (
    <Grid container direction="column" alignItems="center" justify="center" style={{padding: 20}}>
      <p className="form__title">Dispositivo</p>
      {dispo.map((item,key)=>(
          <div key={key} className="form__combobox-container" >
            <SpecialCombobox 
              image={item.src} 
              currentvalue={item.currentvalue} 
              options={item.option} 
              onChange={item.onClick}
            />
          </div>
        ))}
      
        <DevicePhoto onClick={(photo: any) => setItem({...item, photo})}/>
        {(item.photo && (
          <img className="form__upload-photo" src={URL.createObjectURL(item.photo)} alt="upload"/>
        ))}
        <Grid>
          {validation.marca.state ? <></>: <p className="register__error">{validation.marca.message}</p> }
          {validation.estado.state ? <></>: <p className="register__error">{validation.estado.message}</p>}
          {validation.transporte.state ? <></>: <p className="register__error">{validation.transporte.message}</p>}
        </Grid>
      <Grid container direction="row" justify="space-between" alignItems="center" style={{margin:"100px 0"}}>
        <BackButton onClick={onBack}></BackButton>
        <div className="form__add" onClick={()=>{
            ;
            if(item.marca === "Marca" ) {
              setValidation({...validation, marca: {...validation.marca, state:false}});
              return ;
            }
            if(item.estado === "Estado de tu dispositivo" ) {
              setValidation({...validation, estado: {...validation.estado, state:false}});
              return ;
            }
            if(item.transporte === "¿Cómo podemos llevarlo?" ) {
              setValidation({...validation, transporte: {...validation.transporte, state:false}});
              return ;
            }
            item.size='';
            item.weight=''
            setProductList([...productList, item]);
            history.push('/list')
          }}>
          Agregar a tu entrega
        </div>
      </Grid>
    </Grid>
  )}

  function AlternativeStep({history, onBack, item, setItem}:any) {
    const dispo =[
      { currentvalue: item.weight,
        src:peso,
        option:[{
          value:"0",
          label:"1Kg a 10Kg"
        },{
          value:"1",
          label:"10Kg a 50Kg"
        },{
          value:"2",
          label:"50Kg a 100Kg"
        },{
          value:"3",
          label:"100Kg a 500Kg"
        }],
        onClick:({label}:any)=>{setItem({...item,weight:label})}
      },
      { currentvalue: item.size,
        src:transporte,
        option:[{
          value:"0",
          label:"Pequeño: Hasta 40cm x 40cm"
        },{
          value:"1",
          label:"Mediano: Hasta 1,5m x 1,5m"
        },{
          value:"2",
          label:"Grande: Hasta 3m x 3m"
        },{
          value:"3",
          label:"Muy grande: Mas de 3m x 3m"
        }],
        onClick:({label}:any)=>{setItem({...item,size:label})}
      },
    ]

    const {productList, setProductList} = useContext(MainContext);

    useEffect(() => {
      if(item.size !== "Dimensiones") setValidation({...validation, size: {...validation.size, state:true}});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.size]);
  
    useEffect(() => {
      if(item.weight !== "Rango de peso") setValidation({...validation, weight: {...validation.weight, state:true}});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item.weight]);

    const [validation, setValidation] = useState<any>({
      weight: {
        state: true,
        message: "Elige un rango de peso",
      },
      size: {
        state: true,
        message: "Elige las dimensiones",
      },
    });
    
    return (
      <Grid container direction="column" alignItems="center" justify="center" style={{padding: 20}}>
        <p className="form__title">Dispositivo</p>
        {dispo.map((item,key)=>(
            <div key={key} className="form__combobox-container" >
              <SpecialCombobox 
                image={item.src} 
                currentvalue={item.currentvalue} 
                options={item.option} 
                onChange={item.onClick}
              />
            </div>
          ))}
        <DevicePhoto onClick={(photo: any) => setItem({...item, photo})}/>
        {(item.photo && (
          <img className="form__upload-photo" src={URL.createObjectURL(item.photo)} alt="upload"/>
        ))}

        <Grid>
          {validation.weight.state ? <></>: <p className="register__error">{validation.weight.message}</p> }
          {validation.size.state ? <></>: <p className="register__error">{validation.size.message}</p>}
        </Grid>
        <Grid container direction="row" justify="space-between" style={{margin:"100px 0"}}>
          <BackButton onClick={onBack}></BackButton>
          <div className="form__add" onClick={()=>{
            if(item.weight === "Rango de peso" ) {
              setValidation({...validation, weight: {...validation.weight, state:false}});
              return ;
            }
            if(item.size === "Dimensiones" ) {
              setValidation({...validation, size: {...validation.size, state:false}});
              return ;
            }
            item.marca='';
            item.estado='';
            item.transporte='';
            setProductList([...productList, item])
            history.push('/list')
          }}>
            Agregar a tu entrega
          </div>
        </Grid>
      </Grid>
    )}

function Form() {
  const history = useHistory();
  const [item, setItem] = useState({
    userid: localStorage.userid,
    type:'',
    marca:'Marca',
    estado:'Estado de tu dispositivo',
    transporte:'¿Cómo podemos llevarlo?',
    photo:'',
    weight:'Rango de peso',
    size:'Dimensiones',
  });
  let _useFirestore = useFirestore();
 
  const [step, setStep] = useState(0);

  const items1 = [
    { name: "Computador portátil", 
      src:laptop, 
      onClick:()=>{
        setStep(1);
        setItem({...item,type: "Computador portátil"});
      }
    },
    { name: "Cámara",
      src:camara, 
      onClick:()=>{
        setItem({...item,type: "Cámara"});
        setStep(1);
      }
    },
    { name: "Computador de mesa",
      src:pc,
      onClick:()=>{
        setItem({...item,type: "Computador de mesa"});
        setStep(1)
      }
    },
    { name: "Quiero entregar productos por lotes", 
      src: apilar, 
      onClick:()=>{
        setStep(2);
        setItem({...item,type: "Lote"});
      }
    },
  ]
  
  const items2 = [
    { name: "Celular",
      src:celular, 
      onClick:()=>{
        setStep(1);
        setItem({...item,type: "Celular"});
      }
    },
    { name: "Impresora",
      src:impresora,
      onClick:()=>{
        setStep(1);
        setItem({...item,type: "Impresora"});
      }
    },
    { name: "Cables",
      src:lan, onClick:()=>{
        setStep(1);
        setItem({...item,type: "Cables"});
      }
    },
    { name: "Teclado, Mouse, otros periféricos",
      src: perifericos, 
      onClick:()=>{
        setStep(1);
        setItem({...item,type: "Periféricos"});
      }},
  ]


  
  switch (step) {
    case 0:
      return (
        <FirstStep
          items1={items1}
          items2={items2}
        ></FirstStep>
      );
    case 1:
      return (
        <SecondStep
          history={history}
          onBack={()=>setStep(0)}
          item={item}
          setItem={setItem}
          firebase={_useFirestore}
        ></SecondStep>
      );
    case 2:
      return (
        <AlternativeStep 
          history={history}
          onBack={()=>setStep(0)}
          item={item}
          setItem={setItem}
          firebase={_useFirestore}
        ></AlternativeStep>
      );
    default:
      return <p>No Info</p>;
  }

}

export default Form;
