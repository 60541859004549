import React, { FC, useState } from 'react';
import triangle from '../../assets/icons/triangle-bottom.svg'

import './specialCombobox.scss'

interface Option{
  value: string;
  label: string;
}

interface Props{
  image: any;
  currentvalue: string;
  options: Option[]

  onChange: any;
}


const SpecialCombobox: FC<Props> = ({ image, currentvalue, options, onChange } : Props) => {
  
  const [isShowing, setShowing] = useState(false);

  function onClick(item: Option){
    setShowing(false)
    onChange(item)
  }

  return (
    <div className="specialCombobox">
      <div className="specialCombobox__button" onClick={()=>setShowing(!isShowing)}>
        <div className="specialCombobox__button--info">
          <img className="specialCombobox__button--img" src={image} alt="img"/>
          <p className="specialCombobox__button--label">{currentvalue}</p>
        </div>
        <img className="specialCombobox__button--triangle" src={triangle} alt="triangle" />
      </div>
      {isShowing && (
        <div className="specialCombobox__options">
          <div className="specialCombobox__options--scroll">
            {options.map((item, key) => (
              <div key={key} className="specialCombobox__options--option" onClick={()=>onClick(item)}>
                {item.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}


export default SpecialCombobox;
