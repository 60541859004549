import React, { useEffect, useState } from 'react';
import {Grid, InputBase} from '@material-ui/core';
import {useLocation} from 'react-router-dom';
import {useFirestore} from 'reactfire';

import BackButton from '../../../components/backButton/backButton';
import intercambio from '../../../assets/icons/intercambio 1.svg';

import './adminActualizar.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function RecicladorUpdate({state, setState}: any) {
  return (
    <div>
      <table className="adminActualizar__table">
        <tbody>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              Nombre
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.nombre}
                onChange={(event) =>
                  setState({...state, nombre: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio} alt="intercambio"/>
            </td>
          </tr>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              Cédula
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.cedula}
                onChange={(event) =>
                  setState({...state, cedula: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio} alt="intercambio"/>
            </td>
          </tr>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              Dir. de residencia
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.residencia}
                onChange={(event) =>
                  setState({...state, residencia: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio} alt="intercambio"/>
            </td>
          </tr>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              Ciudad
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.ciudad}
                onChange={(event) =>
                  setState({...state, ciudad: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio} alt="intercambio"/>
            </td>
          </tr>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              Celular
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.celular}
                onChange={(event) =>
                  setState({...state, celular: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio} alt="intercambio"/>
            </td>
          </tr>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              M. transporte de RAEE
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.transporte}
                onChange={(event) =>
                  setState({...state, transporte: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio}  alt="intercambio" />
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  );
}

function UserUpdate({state, setState}: any) {
  return (
    <div>
      <table className="adminActualizar__table">
        <tbody>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              Nombre
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.nombre}
                onChange={(event) =>
                  setState({...state, nombre: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio}  alt="intercambio" />
            </td>
          </tr>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              Apellido
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.apellido}
                onChange={(event) =>
                  setState({...state, apellido: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio}  alt="intercambio"/>
            </td>
          </tr>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              Correo
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.correo}
                onChange={(event) =>
                  setState({...state, correo: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio}  alt="intercambio"/>
            </td>
          </tr>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              Ciudad
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.ciudad}
                onChange={(event) =>
                  setState({...state, ciudad: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio}  alt="intercambio"/>
            </td>
          </tr>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              Dirección
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.direccion}
                onChange={(event) =>
                  setState({...state, direccion: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio}  alt="intercambio"/>
            </td>
          </tr>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              Fecha de nacimiento
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.fecha}
                onChange={(event) =>
                  setState({...state, fecha: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio}  alt="intercambio"/>
            </td>
          </tr>
          <tr>
            <td className="adminActualizar__table--gray adminActualizar__table--font">
              Contraseña
            </td>
            <td>
              <InputBase
                className="adminActualizar__table--input adminActualizar__table--font"
                value={state.password}
                onChange={(event) =>
                  setState({...state, password: event.target.value})
                }
              />
            </td>
            <td className="adminActualizar__table--icon">
              <img src={intercambio}  alt="intercambio"/>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const recicladorInfoInitial = {
  nombre: 'XXXXXXXXXXXX XXXXXXXXXXX',
  img:
    'https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png',
  cedula: 'cedula',
  residencia: 'residencia',
  ciudad: 'ciudad',
  celular: 'celular',
  transporte: 'transporte',
  password: 'password',
};

const usuarioInfoInitial = {
  nombre: 'XXXXXXXXXXXX XXXXXXXXXXX',
  img:
    'https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png',
  apellido: 'apellido',
  correo: 'correo',
  ciudad: 'ciudad',
  direccion: 'direccion',
  fecha: 'fecha',
  password: 'password',
};

interface Props {
  isReciclador: boolean;
}

function AdminActualizar({isReciclador}: Props) {
  let _useFirestore = useFirestore();
  let query = useQuery();

  const [usuarioInfo, setUsuarioInfo] = useState(usuarioInfoInitial);
  const [recicladorInfo, setRecicladorInfo] = useState(recicladorInfoInitial);

  let id = query.get('id');

  useEffect(() => {
    if (id) {
      _useFirestore
        .collection('reciclador')
        .doc(id)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            console.log('No such document!');
          } else {
            let item: any = doc.data();
            setRecicladorInfo({...item, img:
              'https://www.kindpng.com/picc/m/495-4952535_create-digital-profile-icon-blue-user-profile-icon.png'});
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function onSubmit(id: any) {
    _useFirestore
      .collection('reciclador')
      .doc(id)
      .set(recicladorInfo, {merge: true})
      .then((result) => {
        alert('Registro modificado');
      });
  }

  function clickBackButton() {
    console.log('Cilck on back button');
  }

  const title = isReciclador
    ? 'Actualización del reciclador ' + recicladorInfo.nombre
    : 'Actualización del usuario : ' + usuarioInfo.nombre;

  const imgSrc = isReciclador ? recicladorInfo.img : usuarioInfo.img;

  return (
    <div className="adminActualizar">
      <Grid container justify="space-between">
        <p className="title-5">{title}</p>
        <BackButton onClick={clickBackButton} />
      </Grid>

      <img src={imgSrc} alt="actualizar icon" className="adminActualizar__profile" />

      {isReciclador ? (
        <RecicladorUpdate state={recicladorInfo} setState={setRecicladorInfo} />
      ) : (
        <UserUpdate state={usuarioInfo} setState={setUsuarioInfo} />
      )}

      <Grid container justify="flex-end">
        <div
          className="adminActualizar__submit-button"
          onClick={() => {
            onSubmit(id);
          }}
        >
          Guardar los cambios
        </div>
      </Grid>
    </div>
  );
}

export default AdminActualizar;
