import React, { useState }  from 'react';
import { Button, Grid,  Modal } from '@material-ui/core';

//assets
import close from '../../assets/close.svg';
import fb from '../../assets/facebook.svg';
import insta from '../../assets/instagram.svg';

//scss
import './footer.scss';

function Footer() {
  const [openAbeas, setOpenAbeas] = useState(false);

  const handleOpenAbeas = () => {
    setOpenAbeas(true);
  };
  const handleCloseAbeas = () => {
    setOpenAbeas(false);
  };
  return (
    <Grid container className="footer footerText" direction="row" justify="space-between" alignItems="center">
      <Grid item style={{paddingLeft:"30px"}}>
        <Button className="footer__privacy" variant="contained" onClick={handleOpenAbeas} >
          Información de privacidad
        </Button>
        <a href="http://wast-e.co/userManual.pdf" target="_blank" rel="noopener noreferrer" style={{textDecorationLine:"unset"}}>
          <Button className="footer__manual" variant="contained">
            Manual de usuario
          </Button>
        </a>
      </Grid>
      <Grid item className="footer_responsive footer_contact" >
        <p className="">®Wast-e Colombia</p>
      </Grid>
      <Grid item className="footer_responsive" style={{padding:"0px 16px"}}>
        <p> Siguenos en: </p>
        <a target="blank" className="footer__social" href="https://instagram.com/wastecol?igshid=19te9hikgxk2e"><img src={insta} alt="Instagram"></img></a>
        <a target="blank" className="footer__social" href="https://www.facebook.com/Wast.eColombia/"><img src={fb} alt="Facebook"></img></a>
      </Grid>
      <Modal
          className="footer__modal"
          open={openAbeas}
          onClose={handleCloseAbeas}
        >
          <Grid className="footer__abeas">
            <Grid container direction="column"  alignContent="flex-end">
              <img src={close} className="footer__close mr-2"  alt="iconclose" onClick={handleCloseAbeas}></img>
            </Grid>
            <Grid className="footer__abeas--text">
              <p>Wast-e es la responsable del tratamiento de los datos que nos suministres.</p> 
              <p>Los datos personales que recabemos, los utilizaremos para las siguientes finalidades:</p>
              <p>Ponernos en contacto para brindarte más información sobre nuestra aplicación. Mantener actualizada nuestra base de datos para nuestra aplicación.</p>
              <p>Bajo ninguna circunstancia, tus datos personales serán compartidos con personas, empresas, organizaciones ni autoridades distintas de la responsable del tratamiento de tus datos.</p>
              <p>En cualquier momento tienes derecho a solicitar por medio electrónico o físico, que tus datos sean dados de baja de nuestra base de datos. ¡Gracias por tu confianza</p>
            </Grid>
          </Grid>
        </Modal>
    </Grid>
  );
}

export default Footer;