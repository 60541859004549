import React, { PropsWithChildren, FC, useRef } from 'react';
import './imageInput.scss';

interface Props{
  onEditImage: (file: File) => any;
}

const ImageInput: FC<PropsWithChildren<Props>> = ({ onEditImage, children }: PropsWithChildren<Props>) => {

  const inputRef = useRef<any>(null);

  function onChangeInput(e: React.ChangeEvent<HTMLInputElement>){
    const files = e.target.files;
    if(files == null || !files.length) return;
    onEditImage(files[0])
  }

  function onClickInput(){
    inputRef.current?.click()
  }

  return (
    <div className="imageInput" onClick={onClickInput}>
       <input 
          type="file" 
          ref={inputRef} 
          onChange={onChangeInput}
          multiple={false}  
          accept="image/*"
          className="imageInput__input"
        />
      {children}
    </div>
  );
}

export default ImageInput;