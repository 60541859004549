import React, { useState} from 'react';
import {Grid, InputBase, Select, MenuItem} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useFirestore, useAuth} from 'reactfire';

import BackButton from '../../../components/backButton/backButton';

import './adminCreation.scss';

interface PropsInput {
  label: string;
  type?: string;
  options?: {
    value: string;
    label: string;
  }[];
  state: any;
  onChange: any;
}

function Input({state, onChange, label, type, options}: PropsInput) {
  if (type === 'select') {
    return (
      <div>
        <p className="adminCreacion__label">{label}</p>
        <Select
          className="adminCreacion__input"
          value={state}
          onChange={(event) => onChange(event.target.value)}
        >
          {options?.map((item, key) => (
            <MenuItem value={item.value} key={key}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  type = type || 'text';
  return (
    <div>
      <p className="adminCreacion__label">{label}</p>
      <InputBase
        className="adminCreacion__input"
        type={type}
        value={state}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
}

function ClientForm({onSubmit}: any) {
  const [state, setState] = useState({
    uid: '',
    name: '',
    nit: '',
    email: '',
    password: '',
    passwordConfirm: '',
    address: '',
    cellphone: '',
    city: '',
    role: 'CLIENT',
  });

  return (
    <div>
      <p className="title-5 adminCreacion__form-title">
        Creación de usuario para clientes
      </p>

      <Grid container justify="space-between">
        <div className="adminCreacion__column">
          <Input
            label="Nombre o Razón Social"
            state={state.name}
            onChange={(name: any) => setState({...state, name})}
          />
          <Input
            label="Nit"
            state={state.nit}
            onChange={(nit: any) => setState({...state, nit})}
          />
          <Input
            label="Correo"
            state={state.email}
            onChange={(email: any) => setState({...state, email})}
          />
          <Input
            label="Número de contacto"
            type="number"
            state={state.cellphone}
            onChange={(cellphone: any) => setState({...state, cellphone})}
          />
        </div>

        <div className="adminCreacion__column">
          <Input
            label="Contraseña"
            type="password"
            state={state.password}
            onChange={(password: any) => setState({...state, password})}
          />
          <Input
            label="Confirmación de contraseña"
            type="password"
            state={state.passwordConfirm}
            onChange={(passwordConfirm: any) =>
              setState({...state, passwordConfirm})
            }
          />
          <Input
            label="Ciudad"
            type="select"
            state={state.city}
            onChange={(city: any) => setState({...state, city})}
            options={[
              {label: 'Bogotá', value: 'Bogota'},
              {label: 'Villavicencio', value: 'Villavicencio'},
            ]}
          />
          <Input
            label="Dirección de entrega"
            state={state.address}
            onChange={(address: any) => setState({...state, address})}
          />
        </div>
      </Grid>

      <Grid container justify="flex-end">
        <div
          className="adminCreacion__submit-button"
          onClick={() => {
            onSubmit(state);
          }}
        >
          Crear
        </div>
      </Grid>
    </div>
  );
}

function RecicladorForm({onSubmit}: any) {

  const [state, setState] = useState({
    nombre: '',
    cedula: '',
    direccion: '',
    fecha: new Date().toUTCString(),
    celular: '',
    ciudad: 'Bogota',
    medio: 'Carro',
    genero: 'Masculino',
    estado: 'ACTIVO',
  });

  return (
    <div>
      <p className="title-5 adminCreacion__form-title">
        Creación de usuario para reciclador
      </p>

      <Grid container justify="space-between">
        <div className="adminCreacion__column">
          <Input
            label="Nombre"
            state={state.nombre}
            onChange={(nombre: any) => setState({...state, nombre})}
          />
          <Input
            label="Cédula"
            state={state.cedula}
            onChange={(cedula: any) => setState({...state, cedula})}
          />
          <Input
            label="Dirección de residencia"
            state={state.direccion}
            onChange={(direccion: any) => setState({...state, direccion})}
          />
          <Input
            label="Fecha de nacimiento"
            type="date"
            state={state.fecha}
            onChange={(fecha: any) => setState({...state, fecha})}
          />
        </div>

        <div className="adminCreacion__column">
          <Input
            label="Celular"
            type="number"
            state={state.celular}
            onChange={(celular: any) => setState({...state, celular})}
          />
          <Input
            label="Ciudad"
            type="select"
            state={state.ciudad}
            onChange={(ciudad: any) => setState({...state, ciudad})}
            options={[
              {label: 'Bogotá', value: 'Bogota'},
              {label: 'Villavicencio', value: 'Villavicencio'},
            ]}
          />
          <Input
            label="Medio de transporte de residuos"
            type="select"
            state={state.medio}
            onChange={(medio: any) => setState({...state, medio})}
            options={[
              {label: 'A pie', value: 'A pie'},
              {label: 'Bicicleta', value: 'Bicicleta'},
              {label: 'Carreta', value: 'Carreta'},
              {label: 'Camión', value: 'Camion'},
            ]}
          />
          <Input
            label="Género"
            type="select"
            state={state.genero}
            onChange={(genero: any) => setState({...state, genero})}
            options={[
              {label: 'Masculino', value: 'Masculino'},
              {label: 'Femenino', value: 'Femenino'},
              {label: 'Otro', value: 'Otro'},
            ]}
          />
        </div>
      </Grid>

      <Grid container justify="flex-end">
        <div
          className="adminCreacion__submit-button"
          onClick={() => {
            onSubmit(state);
            setState({
              nombre: '',
              cedula: '',
              direccion: '',
              fecha: new Date().toUTCString(),
              celular: '',
              ciudad: 'Bogota',
              medio: 'Carro',
              genero: 'Masculino',
              estado: 'ACTIVO',
            });
          }}
        >
          Crear
        </div>
      </Grid>
    </div>
  );
}

interface Props {
  isCliente: boolean;
}

function AdminCreation({isCliente}: Props) {
  let _useFirestore = useFirestore();
  let _useAuth = useAuth();
  let history = useHistory();

  function clientSubmit(data: any) {
    _useAuth
      .createUserWithEmailAndPassword(data.email, data.password)
      .then((result) => {
        let {user}: any = result;
        _useFirestore
          .collection('user')
          .add({...data, uid: user?.uid})
          .then((result) => {
            alert('Se registro correctamente');
          })
          .catch((error) => {
            console.log(error);
          });
      });
  }

  function recicladorSubmit(data: any) {
    _useFirestore
      .collection('reciclador')
      .add(data)
      .then((result) => {
        alert('Se registro correctamente');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function clickBackButton() {
    history.push("/admin/home");
  }

  const title = isCliente ? 'Creación de usuario:' : 'Creación de reciclador:';

  return (
    <div className="adminCreacion">
      <Grid container justify="space-between">
        <p className="title-5">{title}</p>
        <BackButton onClick={clickBackButton} />
      </Grid>

      <div className="adminCreacion__form-container">
        {isCliente ? (
          <ClientForm onSubmit={clientSubmit} />
        ) : (
          <RecicladorForm onSubmit={recicladorSubmit} />
        )}
      </div>
    </div>
  );
}

export default AdminCreation;
