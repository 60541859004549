import React, { FC } from 'react';
import './backButton.scss';

import back from '../../assets/icons/back arrow.svg';

interface Props{
  onClick: any;
}

const BackButton: FC<Props> = ({ onClick }: Props) => {

  return (
    <div className="backButton" onClick={onClick}>
      <img src={back} alt="back_icon"/>
    </div>
  );
}

export default BackButton;