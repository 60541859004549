import React, {useState, useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {useFirestore} from 'reactfire';
import {useHistory} from 'react-router-dom';

import WasteETable from '../../../components/wasteETable/wasteETable';
import PersonalizedInput from '../../../components/personalizedInput/personalizedInput';

import actualizar from '../../../assets/icons/intercambio 1.svg';
import bloquear from '../../../assets/icons/obstruido 1.svg';
import cross from '../../../assets/icons/cross red.svg';

import './adminRecicladores.scss';

function AdminRecicladores() {
  let history = useHistory();
  let _useFirestore = useFirestore();

  const [recicladores, setRecicladores] = useState([]);
  const [recicladoresFilter, setRecicladoresFilter] = useState([]);

  useEffect(() => {
    index();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const index = () =>
    _useFirestore
      .collection('reciclador')
      .get()
      .then((result) => {
        let data: any = [];
        result.forEach((doc: any) => {
          let item = doc.data();
          data.push({...item, id: doc.id});
        });
        setRecicladores(data);
        setRecicladoresFilter(data);
      });

  const edit = (id: string, estado: any) => {
    _useFirestore.collection('reciclador').doc(id).set(
      {
        estado,
      },
      {merge: true}
    );
    index();
  };

  const _remove = (id: string) => {
    let _confirm: boolean = window.confirm('¿Desea eliminar?');
    if (_confirm) {
      _useFirestore.collection('reciclador').doc(id).delete();
      index();
    }
  };

  const search = (value:any) => {
    let data: any = recicladores;
    if(value){  
      let items: any = data.filter((e: any) => e.nombre.toLowerCase().includes(value.toLowerCase()) );
      setRecicladores(items);
    }else{
      setRecicladores(recicladoresFilter)
    }
  }

  return (
    <div className="adminRecicladores">
      <Grid container justify="space-between" alignItems="center">
        <p className="title-5">Gestión de recicladores:</p>
        <div className="green-button" onClick={()=>history.push("/admin/home")}>Menú principal</div>
      </Grid>

      <PersonalizedInput placeholder="Busca aqui..." setValue={search} />

      <Grid container justify="space-between" alignItems="center">
        <p className="title-5">Recicladores:</p>
        <div className="black-button" onClick={()=>history.push("/admin/creationReciclador")}>Crear nuevo reciclador</div>
      </Grid>

      <WasteETable
        data={recicladores}
        setData={setRecicladores}
        columns={[
          {
            label: 'Nombres',
            sort: true,
            property: 'nombre',
          },
          {
            label: 'Celular',
            sort: true,
            property: 'celular',
          },
          {
            label: 'Ciudad',
            sort: true,
            property: 'ciudad',
          },
          {
            label: 'Género',
            sort: true,
            property: 'genero',
          },
          {
            label: 'Estado',
            sort: true,
            property: 'estado',
          },
          {
            label: 'Actualizar',
            image: actualizar,
            onClick: (e: any) => {
              console.log(e);
              history.push('/admin/actualizarReciclador?id=' + e.id);
            },
          },
          {
            label: 'Bloquear',
            image: bloquear,
            onClick: (e: any) => {
              edit(e.id, e.estado === 'ACTIVO' ? 'INACTIVO' : 'ACTIVO');
            },
          },
          {
            label: 'Eliminar',
            image: cross,
            onClick: (e: any) => {
              _remove(e.id);
            },
          },
        ]}
      />
    </div>
  );
}

export default AdminRecicladores;
