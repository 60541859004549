import React  from 'react';
import {Grid} from '@material-ui/core';

import logo from '../../../assets/wastelogo.svg';
import us from '../../../assets/us.svg';
import garzon from '../../../assets/us/garzon.svg';
import beltran from '../../../assets/us/daniela.svg';
import quiroga from '../../../assets/us/ivan.svg';
import acosta from '../../../assets/us/acosta.svg';

// scss
import './nosotros.scss';

// assets


function Nosotros() {

  // useEffect(() => {
  //   console.log(status);
  //   // eslint-disable-next-line
  // }, [status]);

  return (
    <>
      <Grid container alignItems="center" justify="center" direction="column" className="nosotros" >
        <Grid container direction="row" justify="space-around">
          <Grid item xs={12} md={6} container direction="column" justify="center" alignItems="center">
            <Grid container direction="row" justify="center" alignItems="center" style={{marginTop:"40px"}}>
              <p className="nosotros__title" >Somos</p>
              <img style={{marginBottom:"16px",marginLeft:"20px",width:"234px"}} src={logo} alt="logo-waste"></img>
            </Grid>
            <p className="nosotros__subtitle">Cuidamos el medio ambiente, <br/> hacemos parte de la solución</p>
            <img src={us} alt="nosotros" className="nosotros__img"></img>
          </Grid>
          <Grid item xs={12} md={6} container direction="column" justify="center" alignItems="center">
            <p className="nosotros__title" >Nuestra comunidad</p>
            <p className="nosotros__subtitle2" >Promovemos el cuidado del medio ambiente a partir de pequeñas acciones, estamos interesados en las causas sociales y ambientales.</p>
            <p className="nosotros__subtitle2" >Contribuimos a una sociedad sostenible para el futuro, hacemos parte del cambio.</p>
            <p className="nosotros__subtitle2" >Tu también puedes ser parte de la solución ¡Únete!</p>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center" direction="row" style={{margin:"60px 0"}}>
          <Grid item >
            <p className="nosotros__conoce">Conoce al team:</p>
          </Grid>
          <Grid item>
            <img src={garzon} className="nosotros__face" alt="logo waste"></img>
            <p className="nosotros__name">Paula Garzón</p>
            <p className="nosotros__name">CEO</p>
          </Grid>
          <Grid item>
            <img src={acosta} className="nosotros__face" alt="logo waste"></img>
            <p className="nosotros__name">Paula Acosta</p>
            <p className="nosotros__name">COO</p>
          </Grid>
          <Grid item>
            <img src={quiroga} className="nosotros__face" alt="logo waste"></img>
            <p className="nosotros__name">Ivan Quiroga</p>
            <p className="nosotros__name">CTO</p>
          </Grid>
          <Grid item>
            <img src={beltran} className="nosotros__face" alt="logo waste"></img>
            <p className="nosotros__name">Daniela Beltrán</p>
            <p className="nosotros__name">Chief Designer</p>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Nosotros;