import React, {useState, useContext} from 'react';
import {Grid, InputBase, Paper} from '@material-ui/core';
import {useAuth, useFirestore} from 'reactfire';
import * as firebase from 'firebase';

// scss
import './login.scss';

// assets
import logo from '../../../assets/wastelogo.svg';
import google from '../../../assets/google.svg';
import { useHistory } from 'react-router-dom';
import { MainContext } from '../../../context/MainContext'

function Login() {
  const history = useHistory();
  const {setUser} = useContext(MainContext);

  const [form, setForm] = useState({
    username: '',
    password: '',
  });
  let _useAuth = useAuth();
  let _useFirestore = useFirestore();

  const login = (userResult:any)=>{

    const data = userResult.data();
    // console.log("CLG DE USUARIO");
    // console.log(data);
  
    setUser(data);
  
    localStorage.userid = userResult.id;
    switch(data.role){
      case "USER": 
        history.push("/form");
        break;
      case "ADMIN":
        history.push("/admin/home");
        break;
      case "CLIENT":
        history.push("/");
        break;
    }
  
  }

  if(localStorage.userid){
    // console.log("found en localstorage");
    _useFirestore.collection('user').doc(localStorage.userid).get().then((userResult)=>{
      // console.log("userResult");
      // console.log(userResult.data());
      login(userResult);
    });
  }
  

  const loginGoogle = async () => {

    const result = await _useAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());

    const userResult = await _useFirestore.collection('user').where('uid', '==', result?.user?.uid).get();
    if (!userResult.docs.length) {
      console.log("No tiene registro de login");
      return;
    }
    login(userResult.docs[0]);
    
    // alert('Login realizado');
    // window.location.href = '/admin';
  };

  async function loginEmail(){
    const result = await _useAuth.signInWithEmailAndPassword(form.username, form.password);
    let {user}: any = result;
    const userResult = await _useFirestore.collection('user').where('uid', '==', user.uid).get();
    if (!userResult.docs.length) {
      console.log("No tiene registro de login");
      return;
    }
    login(userResult.docs[0]);
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="space-around"
        className="login"
      >
        <Grid item className="login__logodiv">
          <img src={logo} className="login__logo" alt="logo waste"></img>
          <p className="login__logotext">
            ¡Bienvenido a la comunidad que cuida el medio ambiente!
            Recolectamos tu basura tecnológica 
          </p>
          <p className="login__logotext login__logotext--green">
            en tu domicilio sin costo. 
          </p>
          <p className="login__logotext">
            Ayudanos a cuidar vidas, liberar espacio en tu casa y apoyemos a los recolectores Colombianos.
          </p>
        </Grid>
        <Grid item>
          <Paper elevation={5} className="login__background">
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <p className="login__formtitle">Ingresa a nuestra comunidad</p>
              <InputBase
                placeholder="Correo"
                className="login__input"
                onChange={(e: React.ChangeEvent<{value: unknown}>) =>
                  setForm({
                    ...form,
                    username: (e.target as HTMLSelectElement).value,
                  })
                }
              />
              <InputBase
                placeholder="Contraseña"
                type="password"
                className="login__input"
                onChange={(e: React.ChangeEvent<{value: unknown}>) =>
                  setForm({
                    ...form,
                    password: (e.target as HTMLSelectElement).value,
                  })
                }
              />
              {/* <button onClick={login}>Ingresar</button>
              <button onClick={loginGoogle}>Ingresar Google</button> */}

              <div style={{maxWidth: '80%'}}>
                <p className="login__formtitle" style={{marginBottom: '0px'}}>
                  {' '}
                  ¿Aún no perteneces a nuestra comunidad?
                </p>
              </div>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{maxWidth: '80%'}}
              >
                <Grid item container alignItems="center" justify="space-around" style={{width:"80%"}}>
                  <Grid
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <p className="login__formsubtitle">¿Eres un usuario?</p>
                    <div className="black-button-register" onClick={()=>{ history.push({ pathname: '/register', state: {isCompany:false}}) }}>
                      Únete
                    </div>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <p className="login__formsubtitle">¿Eres una empresa?</p>
                    <div className="black-button-register" onClick={()=>{ history.push({ pathname: '/register', state: {isCompany:true}}) }}>
                      Únete
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{maxWidth: '70%', margin: '32px 0'}}
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid>
                    <img
                      onClick={loginGoogle}
                      src={google}
                      style={{cursor: 'pointer'}}
                      alt="google"
                    ></img>
                  </Grid>
                  <Grid>
                    <div onClick={loginEmail} className="gray-button-login">
                      ¡Vamos!
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
