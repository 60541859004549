import React, { useEffect, useState } from 'react';
import './adminEntregas.scss';
import { Grid } from '@material-ui/core';

import WasteETable from '../../../components/wasteETable/wasteETable';

import gestionar from '../../../assets/icons/horario-de-trabajo 1.svg';
import cross from '../../../assets/icons/cross red.svg';
import lupa from '../../../assets/icons/buscar 1.svg';
import { useFirestore } from 'reactfire';
import { useHistory } from 'react-router'


function AdminEntregas({ onlyView, end, curse }: any){

  const [entregas, setEntregas] = useState<any[]>([]);

  const _useFirestore = useFirestore();

  async function getEntregas(){

    let state;
    if (end) state = "END"
    else if(curse) state = "EN CURSO"
    else state = "NEW";

    const result = await _useFirestore.collection('entrega').where('state', "==", state).get();
    const arr:any[] = [];
    for(const res of result.docs) arr.push({...res.data(), id: res.id});
    const tableInfo = [];

    for(const entrega of arr){
      tableInfo.push({
        fechaCreacion: new Date(entrega.creation.seconds * 1000),
        fechaRecoger: new Date(entrega.date.seconds * 1000),
        dispositivo: entrega.productList.map((el:any)=>el.type).join(', '),
        ciudad: entrega.city,
        direccion: entrega.address,
        id: entrega.id,
      })
    }
    setEntregas(tableInfo);
  }

  useEffect(()=>{
    getEntregas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const history = useHistory();

  function gestionarFunc(data: any){
    history.push('/admin/entregaCoordinar/' + data.id)
  }
  

  return (
    <div className="adminEntregas">
      <Grid container justify="space-between" alignItems="center">
        <p className="title-5">{onlyView ? <>Entregas en curso</> : <>Entregas a coordinar</>}</p>
        <div className="green-button" onClick={()=>history.push("/admin/home")}>Menú principal</div>
      </Grid>

      <WasteETable 
        data={entregas}
        setData={setEntregas}
        columns={[{
          label:"Fecha de creación",
          sort: true,
          property: "fechaCreacion",
          type:"date",
        }, {
          label:"Fecha a recoger",
          sort: true,
          property: "fechaRecoger",
          type:"date",
        },{
          label:"Dispositivo",
          sort: true,
          property: "dispositivo",
        },{
          label:"Ciudad",
          sort: true,
          property: "ciudad",
        },{
          label:"Dirección",
          sort: true,
          property: "direccion",
        },{
          label: onlyView ? "Visualizar" : "Gestionar",
          image: onlyView ? lupa : gestionar,
          onClick: gestionarFunc,
        },{
          label:"Eliminar",
          image: cross,
        },]}
      />

    </div>
  );
}

export default AdminEntregas;